import { RouteConfig } from '../interfaces/routes-config.interface';
import { UserRole } from '../enums/user-role.enum';
// tslint:disable: no-console
console.group();
console.info('*** PUBLIC PATH *** ', process.env.PUBLIC_URL);
console.info('*** BE ROUTE ***', process.env.REACT_APP_BE_API_ENDPOINT);
console.groupEnd();
// tslint:enable: no-console
export const PUBLIC_PATH = process.env.PUBLIC_URL || '/';
export const SERVER_BASE_URL = process.env.REACT_APP_BE_API_ENDPOINT;
export const HOST_NAME = '//' + window.location.host;
export const WEBSITE_HOME = process.env.REACT_APP_WEBSITE_BASE || 'https://birdnestlife.com';
export const GOOGLE_CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

export const APP_ROUTES: RouteConfig = {
  HOME_MODULE: {
    PATH: '/home',
    FULL_PATH: '/home',
    DISPLAY_NAME: 'Dashboard',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'home',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/home.png`
  },
  DASHBOARD_MODULE: {
    PATH: '/dashboard',
    FULL_PATH: '/dashboard',
    DISPLAY_NAME: 'Dashboard',
    NAME: 'dashboard',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    CHILDREN: {
      HOME: {
        PATH: '/home',
        FULL_PATH: '/dashboard/home',
        NAME: 'Home',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      }
    }
  },
  KPIS_MODULE: {
    PATH: '/kpis',
    FULL_PATH: '/kpis',
    DISPLAY_NAME: 'Kpis',
    NAME: 'Kpis',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    CHILDREN: {
      HOME: {
        PATH: '/home',
        FULL_PATH: '/kpis/home',
        NAME: 'Kpis',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      }
      // BUSINESS_ON_THE_BOOK: {
      //   PATH: '/BusinessOnTheBook',
      //   FULL_PATH: '/kpis/BusinessOnTheBook',
      //   DISPLAY_NAME: 'Business on the book',
      //   NAME: 'Business on the book',
      //   ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
      //   IS_MENU: false,
      //   IS_MENU_LOOKUP: true,
      //   IS_MENU_USERS: false
      // }
    }
  },
  AUTH_MODULE: {
    PATH: '/auth',
    FULL_PATH: '/auth',
    NAME: 'auth',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    CHILDREN: {
      LOGIN: {
        PATH: '/login',
        FULL_PATH: '/auth/login',
        NAME: 'Sign in',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      },
      RESET_PASSWORD: {
        PATH: '/reset-password',
        FULL_PATH: '/auth/reset-password',
        NAME: 'Reset password',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      },
      FORGOT_PASSWORD: {
        PATH: '/forgot-password',
        FULL_PATH: '/auth/forgot-password',
        NAME: 'Forgot password',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      },
      UNAUTHORIZED: {
        PATH: '/unauthorized',
        FULL_PATH: '/auth/unauthorzied',
        NAME: 'Unauthorized',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      }
    }
  },
  AREA_MODULE: {
    PATH: '/areas',
    FULL_PATH: '/areas',
    DISPLAY_NAME: 'Areas',
    NAME: 'areas',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE, UserRole.ECOMMERCE_SPECIALIST,
    UserRole.ECOMMERCE_SENIOR_SPECIALIST, UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/areas/list',
        NAME: 'Areas List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE, UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST, UserRole.ADMIN]
      }
    }
  },
  UNIT_TYPE_GROUP_MODULE: {
    PATH: '/unitTypeGroups',
    FULL_PATH: '/unitTypeGroups',
    DISPLAY_NAME: 'Accommodation Types',
    NAME: 'unitTypeGroups',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE, UserRole.ECOMMERCE_SPECIALIST,
    UserRole.ECOMMERCE_SENIOR_SPECIALIST, UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/unitTypeGroups/list',
        NAME: 'Accommodation Type List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE, UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST, UserRole.ADMIN]
      }
    }
  },
  AMENITY_MODULE: {
    PATH: '/amenity',
    FULL_PATH: '/amenity',
    DISPLAY_NAME: 'Amenities',
    NAME: 'amenity',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/amenities.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE, UserRole.ECOMMERCE_SPECIALIST,
    UserRole.ECOMMERCE_SENIOR_SPECIALIST, UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/amenity/list',
        NAME: 'Amenities List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE, UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST, UserRole.ADMIN]
      }
    }
  },
  USER_MODULE: {
    PATH: '/user',
    FULL_PATH: '/user',
    DISPLAY_NAME: 'User Search',
    NAME: 'user',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: true,
    NEW_LABEL: true,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/user-profile.png`,
    PERMITTED_ROLES: [
      UserRole.E_COMMERCE,
      UserRole.ECOMMERCE_SPECIALIST,
      UserRole.ECOMMERCE_SENIOR_SPECIALIST,
      UserRole.SALES_SPECIALIST,
      UserRole.ADMIN,
      UserRole.RESERVATIONS,
      UserRole.RESERVATION_MANAGER,
      UserRole.RESERVATION_AGENT,
      UserRole.HOSPITALITY_MANAGER,
      UserRole.HOSPITALITY_AGENT,
      UserRole.OPERATION_MANAGER,
      UserRole.COMMERCIAL_MANAGER
    ],
    CHILDREN: {
      SEARCH: {
        PATH: '/search',
        FULL_PATH: '/user/search',
        NAME: 'User Search',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: true,
        PERMITTED_ROLES: [
          UserRole.E_COMMERCE,
          UserRole.ECOMMERCE_SPECIALIST,
          UserRole.ECOMMERCE_SENIOR_SPECIALIST,
          UserRole.SALES_SPECIALIST,
          UserRole.ADMIN,
          UserRole.RESERVATIONS,
          UserRole.RESERVATION_MANAGER,
          UserRole.RESERVATION_AGENT,
          UserRole.HOSPITALITY_MANAGER,
          UserRole.HOSPITALITY_AGENT,
          UserRole.OPERATION_MANAGER,
          UserRole.COMMERCIAL_MANAGER
        ]
      }
    }
  },
  BOOKING_MODULE: {
    PATH: '/booking',
    FULL_PATH: '/booking',
    DISPLAY_NAME: ' Booking Requests',
    NAME: ' Booking Requests',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NOTIFICATIONS_KEY: 'bookingRequests',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/amenities.png`,
    PERMITTED_ROLES: [UserRole.RESERVATION_AGENT,
    UserRole.RESERVATION_MANAGER,
    UserRole.ADMIN,
    UserRole.COMMERCIAL_MANAGER,
    UserRole.RESERVATIONS],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/booking/list',
        NAME: ' Booking Requests List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.RESERVATION_AGENT,
        UserRole.RESERVATION_MANAGER,
        UserRole.ADMIN,
        UserRole.COMMERCIAL_MANAGER,
        UserRole.RESERVATIONS]
      }
    }
  },
  BLOCK_DATES_MODULE: {
    PATH: '/block-dates',
    FULL_PATH: '/block-dates',
    DISPLAY_NAME: 'Block Dates',
    NAME: 'Block Dates',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NOTIFICATIONS_KEY: 'blockDates',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/amenities.png`,
    PERMITTED_ROLES: [UserRole.RESERVATION_AGENT,
    UserRole.RESERVATION_MANAGER,
    UserRole.ECOMMERCE_SPECIALIST,
    UserRole.ECOMMERCE_SENIOR_SPECIALIST,
    UserRole.RESERVATIONS,
    UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/block-dates/list',
        NAME: 'Block Dates List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.RESERVATION_AGENT,
        UserRole.RESERVATION_MANAGER,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.RESERVATIONS,
        UserRole.ADMIN]
      }
    }
  },
  PROPERTY_MODULE: {
    PATH: '/properties',
    FULL_PATH: '/properties',
    DISPLAY_NAME: 'Projects',
    NAME: 'projects',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE,
    UserRole.ECOMMERCE_SPECIALIST,
    UserRole.ECOMMERCE_SENIOR_SPECIALIST,
    UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/properties/list',
        NAME: 'Projects List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.ADMIN]
      },
      EDIT: {
        PATH: '/edit/:id',
        FULL_PATH: '/properties/edit/:id',
        NAME: 'Edit Project',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.ADMIN]
      }
    }
  },
  UNIT_TYPE_MODULE: {
    PATH: '/unit-types',
    FULL_PATH: '/unit-types',
    DISPLAY_NAME: 'Unit Types',
    NAME: 'unit-types',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE,
    UserRole.ECOMMERCE_SPECIALIST,
    UserRole.ECOMMERCE_SENIOR_SPECIALIST,
    UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/unit-types/list',
        NAME: 'projects List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.RESERVATION_MANAGER,
        UserRole.ADMIN]
      },
      EDIT: {
        PATH: '/edit/:id',
        FULL_PATH: '/unit-types/edit/:id',
        NAME: 'Edit project',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.E_COMMERCE,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.RESERVATION_MANAGER,
        UserRole.ADMIN]
      },
      BULK_EDIT: {
        PATH: '/bulk-edit/',
        FULL_PATH: '/unit-types/bulk-edit',
        NAME: 'Edit project',
        DISPLAY_NAME: 'Unit Types Bulk Update',
        IS_MENU: false,
        IS_MENU_LOOKUP: true,
        IS_MENU_USERS: false,
        ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
        PERMITTED_ROLES: [UserRole.E_COMMERCE,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.ADMIN]
      },
      BULK_EDIT_LIST: {
        PATH: '/bulk-edit-list/',
        FULL_PATH: '/unit-types/bulk-edit-list',
        NAME: 'Edit project',
        DISPLAY_NAME: 'Unit Types Bulk Update list',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
        PERMITTED_ROLES: [UserRole.E_COMMERCE,
        UserRole.ECOMMERCE_SPECIALIST,
        UserRole.ECOMMERCE_SENIOR_SPECIALIST,
        UserRole.ADMIN]
      }
    }
  },
  EXPENSES_MODULE: {
    PATH: '/expenses',
    FULL_PATH: '/expenses',
    DISPLAY_NAME: 'Expenses',
    NAME: 'expenses',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE,
    UserRole.FINANCE_MANAGER,
    UserRole.FINANCE_SPECIALIST,
    UserRole.ADMIN]
  },
  PAYOUT_MODULE: {
    PATH: '/payout',
    FULL_PATH: '/payout',
    DISPLAY_NAME: 'Payout',
    NAME: 'payout',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.E_COMMERCE,
    UserRole.FINANCE_MANAGER,
    UserRole.ADMIN,
    UserRole.FINANCE_SPECIALIST]
  },
  RESERVATION_MODULE: {
    PATH: '/reservations',
    FULL_PATH: '/reservations',
    DISPLAY_NAME: 'Reservations',
    NAME: 'reservations',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NOTIFICATIONS_KEY: 'reservations',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/reservation.png`,
    PERMITTED_ROLES: [
      UserRole.RESERVATION_AGENT,
      UserRole.RESERVATION_MANAGER,
      UserRole.ADMIN,
      UserRole.COMMERCIAL_MANAGER,
      UserRole.RESERVATIONS,
      UserRole.HOSPITALITY_AGENT,
      UserRole.HOSPITALITY_MANAGER,
      UserRole.FINANCE_MANAGER,
      UserRole.FINANCE_SPECIALIST,
      UserRole.OPERATION_MANAGER,
      UserRole.ECOMMERCE_SENIOR_SPECIALIST
    ],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/reservations/list',
        NAME: 'Reservations List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [
          UserRole.RESERVATION_AGENT,
          UserRole.RESERVATION_MANAGER,
          UserRole.ADMIN,
          UserRole.COMMERCIAL_MANAGER,
          UserRole.RESERVATIONS,
          UserRole.HOSPITALITY_AGENT,
          UserRole.HOSPITALITY_MANAGER,
          UserRole.FINANCE_MANAGER,
          UserRole.FINANCE_SPECIALIST,
          UserRole.OPERATION_MANAGER,
          UserRole.ECOMMERCE_SENIOR_SPECIALIST
        ]
      },
      EDIT: {
        PATH: '/edit/:id',
        FULL_PATH: '/reservations/edit/:id',
        NAME: 'Edit Reservation',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [
          UserRole.RESERVATION_AGENT,
          UserRole.RESERVATION_MANAGER,
          UserRole.ADMIN,
          UserRole.COMMERCIAL_MANAGER,
          UserRole.RESERVATIONS,
          UserRole.HOSPITALITY_AGENT,
          UserRole.HOSPITALITY_MANAGER,
          UserRole.FINANCE_MANAGER,
          UserRole.FINANCE_SPECIALIST,
          UserRole.OPERATION_MANAGER,
          UserRole.ECOMMERCE_SENIOR_SPECIALIST
        ]
      },
      FEEDBACK_LIST: {
        PATH: '/feedbacks',
        FULL_PATH: '/reservations/feedbacks',
        DISPLAY_NAME: 'Feedbacks',
        NAME: 'feedbacks',
        IS_MENU: false,
        IS_MENU_LOOKUP: true,
        IS_MENU_USERS: false,
        ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
        PERMITTED_ROLES: [
          UserRole.ADMIN,
          UserRole.HOSPITALITY_AGENT,
          UserRole.HOSPITALITY_MANAGER,
          UserRole.OPERATION_MANAGER
        ]
      },
      FEEDBACK_DETAILS: {
        PATH: '/feedback/:id',
        FULL_PATH: '/reservations/feedback/:id',
        NAME: 'Feedback details',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [
          UserRole.ADMIN,
          UserRole.HOSPITALITY_AGENT,
          UserRole.HOSPITALITY_MANAGER,
          UserRole.OPERATION_MANAGER
        ]
      },
    }
  },
  PAYMENTS_MODULE: {
    PATH: '/payments',
    FULL_PATH: '/payments',
    DISPLAY_NAME: 'Payments',
    NAME: 'payments',
    IS_MENU: false,
    IS_MENU_LOOKUP: true,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.FINANCE_MANAGER,
    UserRole.ADMIN,
    UserRole.FINANCE_SPECIALIST, UserRole.RESERVATION_MANAGER],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/payments/list',
        NAME: 'Payments List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.FINANCE_MANAGER,
        UserRole.ADMIN,
        UserRole.FINANCE_SPECIALIST,
        UserRole.RESERVATION_MANAGER]
      }
    }
  },
  REPORTS_MODULE: {
    PATH: '/reports',
    FULL_PATH: '/reports',
    DISPLAY_NAME: 'Reports',
    NAME: 'Reports',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.FINANCE_MANAGER,
    UserRole.ADMIN,
    UserRole.FINANCE_SPECIALIST],
    CHILDREN: {
      FINANCIAL: {
        PATH: '/financial',
        FULL_PATH: '/reports/financial',
        NAME: 'Financial Report',
        ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
        DISPLAY_NAME: 'Financial Report',
        IS_MENU: false,
        IS_MENU_LOOKUP: true,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.FINANCE_MANAGER,
        UserRole.ADMIN,
        UserRole.FINANCE_SPECIALIST]
      }
    }
  }
};

export const SETTINGS_ROUTES: RouteConfig = {
  ACCOUNT_SETTINGS_MODULE: {
    PATH: '/settings',
    FULL_PATH: '/setting',
    DISPLAY_NAME: 'Account Settings',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'account settings',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/settings.png`
  },
  ACCOUNT_CONFIGURATION_MODULE: {
    PATH: '/configuration',
    FULL_PATH: '/configuration',
    DISPLAY_NAME: 'Configuration',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'configuration',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/settings.png`,
    PERMITTED_ROLES: [UserRole.ADMIN]
  },
  USER_PROFILE_MODULE: {
    PATH: '/user-profile',
    FULL_PATH: '/user-profile',
    DISPLAY_NAME: 'Profile',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'user profile',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/user-profile.png`
  },
  LOGOUT_MODULE: {
    PATH: '/logout',
    FULL_PATH: '/logout',
    DISPLAY_NAME: 'Logout',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'logout',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/logout.png`
  }
};
