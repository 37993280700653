import { UserModel } from '../models/user.model';
import { UserUIModel } from '../ui-models/user.ui-model';

function mapToUI(userData: UserModel): UserUIModel {
  return {
    id: userData.id,
    name: userData.name,
    email: userData.email,
    gender: userData.gender,
    phone: userData.phone,
    role: userData.role,
    roles: userData.roles,
    socialLink: userData.socialLink,
    createdAt: userData.createdAt,
    isPhoneSuspended: userData.isPhoneSuspended,
    isPhoneVerified: userData.isPhoneVerified
  };
}

function mapToModel(userData: Partial<UserUIModel>): UserModel {
  return {
    id: userData.id,
    name: userData.name,
    email: userData.email,
    gender: userData.gender,
    phone: userData.phone,
    role: userData.role,
    roles: userData.roles,
    socialLink: userData.socialLink,
    createdAt: userData.createdAt,
    captchaToken: userData.token
  };
}

function mapToUIList(usersList: UserModel[]): UserUIModel[] {
  return usersList.map(mapToUI);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList
};
