import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './edit-unit-type.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'timeago.js';
import { store } from 'react-notifications-component';
import Tooltip from '@material-ui/core/Tooltip';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { Expenses, UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  updateUnitTypeData,
  uploadUnitTypeShowRoomImage,
  deleteUnitTypeShowroomImage,
  uploadUnitTypeImage,
  deleteUnitTypeImage,
  createExpenses,
  deleteExpense,
  createPayouts,
  deletePayout,
  searchPayouts,
  syncUnitTypeChannel
} from '../../shared/services/data.service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { orange } from '@material-ui/core/colors';

import { RouteComponentProps } from 'react-router';
import EditGeneralInfo from './components/edit-general-info/edit-general-info.container';
import { getAllAmenities, getAllUnitTypeGroups } from '../../../shared/services/data/lookups.data';
import { getModuleName } from '../../shared/services/unit-type.service';
import { AmenityUiModel } from '../../../shared/ui-models/amenity.ui-model';
import SelectAmenities from './components/select-amenities/select-amenities.container';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { getUnitTypeDetails } from '../../../shared/services/data/unit-type.data';
import EditFinancialInfo from './components/edit-financial-info/edit-financial-info.container';
import { ReservationMode } from '../../../shared/enums/reservation-mode.enum';
import EditVisuals from './components/edit-visuals/edit-visuals.container';
import { ImageListType, ImageType } from 'react-images-uploading';
import Rooms from './components/rooms/rooms.container';
import EditPlanInfo from './components/edit-plans-info/edit-plan-info.container';
import { UnitTypeGroupUiModel } from '../../../shared/ui-models/unit-type-group.ui-model';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import ExpensesListContainer from './expenses-list/expenses-list.container';
import PayoutsListContainer from './payouts-list/payouts-list.container';
import { Payout, SearchPayOutDto } from '../../../shared/models/payout.model';
import { LandLordInfo } from './components/landlord-info/landlord-info.container';
import { AssignLandlordModal } from '../unit-types-list/components/assign-landlord-modal/assign-landlord-modal.component';
import { CustomPrice } from './components/custom-price/custom-price.container';
import { CustomPriceModel } from '../../../shared/models/unit-types.model';
import { BookingMode } from '../../../shared/enums/booking-mode.enum';
import { AssignPartnerModal } from '../unit-types-list/components/assign-partner-modal/assign-partner-modal.component';
import { PartnerInfo } from './components/partner-info/partner-info.container';
import { getExpenses } from '../../../ExpensesModule/shared/services/data.service';
import { Loader } from '../../../shared/components/core-ui/loader/loader.component';

const OrangeSwitch = withStyles({
  switchBase: {
    // color: orange[300],
    '&$checked': {
      color: orange[500]
    },
    '&$checked + $track': {
      backgroundColor: orange[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

interface EditUnitTypeRouteParams {
  id: string;
}

export type EditUnitTypeProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<EditUnitTypeRouteParams>;

/**
 * functional component EditUnitType
 * @param {EditUnitTypeProps} holding question text
 */
export const EditUnitType: React.FC<EditUnitTypeProps> = ({ match, isAuthenticated }) => {
  const [unitType, setUnitType] = useState<UnitTypeUiModel>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [syncingChannelData, setSyncingChannelData] = useState<boolean>(false);
  const [loadingAmenities, setLoadingAmenities] = useState<boolean>(false);
  const [amenitiesList, setAmenitiesList] = useState<AmenityUiModel[]>();
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<UnitTypeGroupUiModel[]>([]);
  const [isCopy, setIsCopy] = useState(false);
  const [tabKey, setTabKey] = useState('Listing');
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [disabledListSwitcher, setDisabledListSwitcher] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showAssignUserModal, setShowAssignUserModal] = useState<boolean>(false);
  const [payoutstoUpdate, setPayoutstoUpdate] = useState<string[]>();
  const [showAssignPartnerModal, setShowAssignPartnerModal] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<Expenses[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    trackPage('edit-unitType');
    setTabKey('Listing');
    const { id: unitTypeId } = match.params;
    setLoadingData(true);
    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response);
      }
    });
    getUnitDetails(unitTypeId, getModuleName());
    return () => {
      store.removeNotification('edit-unit-type-failed');
      store.removeNotification('edit-unit-type-success');
      store.removeNotification('delete-unitType-failed');
      store.removeNotification('delete-unitType-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUnitDetails = (unitTypeId: string, moduleName: string): void => {
    getUnitTypeDetails(unitTypeId, moduleName).then((response: UnitTypeUiModel | ErrorInfo) => {
      setLoadingData(false);
      if (!isErrorInfo(response)) {
        setUnitType(response);
        fetchAmenitiesData();
        searchPayout({ unitTypeId: Number(response?.id) || 0 });
        getExpenses({ unitTypeId: Number(response?.id) }).then((response) => {
          if (!isErrorInfo(response)) {
            setExpenses(response);
          }
        });
      } else {
        store.addNotification({
          id: 'get-unit-type-details',
          title: 'Error occurred',
          message: response.errorMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    });
  };
  const fetchAmenitiesData = () => {
    setLoadingAmenities(true);
    getAllAmenities(getModuleName()).then((response: AmenityUiModel[] | ErrorInfo) => {
      setLoadingAmenities(false);
      if (!isErrorInfo(response)) {
        setAmenitiesList(response);
      }
    });
  };

  const handleChannelManagerSync = () => {
    if (unitType && unitType.id) {
      setSyncingChannelData(true);
      syncUnitTypeChannel(unitType.id).then((response: UnitTypeUiModel | ErrorInfo) => {
        setSyncingChannelData(false);
        if (!isErrorInfo(response)) {
          store.addNotification({
            id: 'sync-unit-type-success',
            title: 'Sync Successful',
            message: 'Unit Type synced with Channel Manager Successfully',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          // setUnitType({
          //   ...unitType,
          // });
        } else {
          store.addNotification({
            id: 'sync-unit-type-failed',
            title: 'Sync Failed',
            message: 'Unit Type failed to sync with Channel Manager. Please contact support',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      });
    }
  };

  const handleUpdateUnitType = (data: Partial<UnitTypeUiModel>) => {
    setShowLoader(true);
    if (unitType && unitType.id) {
      setDisabledListSwitcher(true);
      setSyncingChannelData(true);
      updateUnitTypeData(unitType.id, data).then((response: UnitTypeUiModel | ErrorInfo) => {
        setDisabledListSwitcher(false);
        setSyncingChannelData(false);
        if (!isErrorInfo(response)) {
          store.addNotification({
            id: 'edit-unit-type-success',
            title: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.TITLE'
            ),
            message: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.MESSAGE'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          setUnitType({
            ...unitType,
            name: response.name,
            description: response.description,
            maxGuests: response.maxGuests,
            unitMeters: response.unitMeters,
            bedrooms: response.bedrooms,
            bathrooms: response.bathrooms,
            unitTypeLink: response.unitTypeLink,
            viewLink: response.viewLink,
            isListed: response.isListed,
            isManaged: response.isManaged,
            bookingMode: response.bookingMode,
            amenities: response.amenities,
            houseRules: response.houseRules,
            ratePlans: response.ratePlans,
            fees: response.fees,
            onboardingMessage: response.onboardingMessage,
            landlordId: response.landlordId,
            landlordEmail: response.landlordEmail,
            landlordPhone: response.landlordPhone,
            landlordUsername: response.landlordUsername,
            landlordShare: response.landlordShare,
            partnerId: response.partnerId,
            partnerEmail: response.partnerEmail,
            partnerPhone: response.partnerPhone,
            partnerUsername: response.partnerUsername,
            partnerShare: response.partnerShare,
            roomTypePhotos: response.roomTypePhotos,
            customPrices: response.customPrices,
            beds: response.beds,
            cmId: response.cmId,
            cmLastModifiedAt: response.cmLastModifiedAt,
            cmLastModifiedByUsername: response.cmLastModifiedByUsername,
            cmLastModifiedById: response.cmLastModifiedById,
            unitPrice: response?.unitPrice,
            minStayDuration: response?.minStayDuration,
            maxStayDuration: response?.maxStayDuration,
            downPayment: response?.downPayment,
            insurance: response?.insurance,
            reservationMode: response?.reservationMode,
            ...response
          });
          setShowLoader(false);
          // if (unitType.cmId) {
          //   handleChannelManagerSync();
          // }
        } else {
          setShowLoader(false);
          store.addNotification({
            id: 'edit-unit-type-failed',
            title: t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
            message: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      });
    }
  };

  const uploadShowRoomImageHandler = (roomName: string, images: ImageListType) => {
    if (unitType && unitType.id && images.length > 0 && images) {
      uploadUnitTypeShowRoomImage(roomName, handleImageData(images), unitType.id).then(
        (response: UnitTypeUiModel | ErrorInfo) => {
          if (!isErrorInfo(response)) {
            store.addNotification({
              id: 'edit-unit-type-success',
              title: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.TITLE'
              ),
              message: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.MESSAGE'
              ),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            setUnitType({
              ...unitType,
              showRooms: response.showRooms
            });
          } else {
            store.addNotification({
              id: 'edit-unit-type-failed',
              title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
              message: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        }
      );
    }
  };
  const handleImageData = (images: ImageListType) => {
    const imagesList: FileList[] = [];
    images.map((image: ImageType) => image.file && imagesList.push(image?.file));
    return imagesList;
  };
  const uploadImageHandler = (images: ImageListType) => {
    if (unitType && unitType.id && images.length > 0 && images) {
      setSyncingChannelData(true);
      uploadUnitTypeImage(handleImageData(images), unitType.id).then(
        (response: UnitTypeUiModel | ErrorInfo) => {
          setSyncingChannelData(false);
          if (!isErrorInfo(response)) {
            store.addNotification({
              id: 'edit-unit-type-success',
              title: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.TITLE'
              ),
              message: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.MESSAGE'
              ),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            setUnitType({
              ...unitType,
              roomTypePhotos: response.roomTypePhotos,
              cmId: response.cmId,
              cmLastModifiedAt: response.cmLastModifiedAt,
              cmLastModifiedByUsername: response.cmLastModifiedByUsername,
              cmLastModifiedById: response.cmLastModifiedById
            });
            // if (unitType.cmId) {
            //   handleChannelManagerSync();
            // }
          } else {
            store.addNotification({
              id: 'edit-unit-type-failed',
              title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
              message: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        }
      );
    }
  };

  const handleDeleteShowroomImageHandler = (imageId: string, roomId: string) => {
    if (unitType && unitType.id) {
      deleteUnitTypeShowroomImage(unitType.id, imageId, roomId).then(
        (response: UnitTypeUiModel | ErrorInfo) => {
          if (!isErrorInfo(response)) {
            store.addNotification({
              id: 'edit-unit-type-success',
              title: t(
                'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.TITLE'
              ),
              message: t(
                'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.MESSAGE'
              ),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            setUnitType({
              ...unitType,
              showRooms: response.showRooms
            });
          } else {
            store.addNotification({
              id: 'edit-unit-type-failed',
              title: t(
                'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
              ),
              message: t(
                'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        }
      );
    }
  };
  const handleDeleteImageHandler = (imageId: string) => {
    if (unitType && unitType.id) {
      setSyncingChannelData(true);
      deleteUnitTypeImage(unitType.id, imageId).then((response: UnitTypeUiModel | ErrorInfo) => {
        setSyncingChannelData(false);
        if (!isErrorInfo(response)) {
          store.addNotification({
            id: 'edit-unit-type-success',
            title: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.TITLE'
            ),
            message: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.MESSAGE'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          setUnitType({
            ...unitType,
            roomTypePhotos: response.roomTypePhotos,
            cmId: response.cmId,
            cmLastModifiedAt: response.cmLastModifiedAt,
            cmLastModifiedByUsername: response.cmLastModifiedByUsername,
            cmLastModifiedById: response.cmLastModifiedById
          });
          // if (unitType.cmId) {
          //   handleChannelManagerSync();
          // }
        } else {
          store.addNotification({
            id: 'edit-unit-type-failed',
            title: t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
            message: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      });
    }
  };
  const searchPayout = (searchPayoutData: SearchPayOutDto) => {
    searchPayouts(searchPayoutData).then((response) => {
      if (!isErrorInfo(response)) {
        setPayouts(response);
      }
    });
  };
  const addLandLord = (payoutsToUpdate: string[]) => {
    setShowAssignUserModal(true);
    setPayoutstoUpdate(payoutsToUpdate);
  };
  const deleteLandLord = () => {
    handleUpdateUnitType({ landlordId: '', landlordShare: 0, landlordUsername: '' });
  };

  const addPartner = () => {
    setShowAssignPartnerModal(true);
  };
  const deletePartner = () => {
    handleUpdateUnitType({ partnerId: '', partnerShare: 0, partnerUsername: '' });
  };
  if (showLoader)
    return (
      <div className={styles['edit-unit-type-loader']}>
        <Loader />
      </div>
    );
  return (
    <div className={styles['edit-unit-type-container']}>
      {loadingData ? (
        <div className={styles['edit-unit-type-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <div className={styles['edit-unit-type-container__header']}>
            <h1>
              {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.TITLE')}
              <div className={styles['edit-unit-type-container__copy-container']}>
                <a href={`https://${unitType?.urlLink}`} target="_blank" rel="noopener noreferrer">
                  {`https://${unitType?.urlLink}`}{' '}
                </a>
                <button
                  className={styles['edit-unit-type-container__copy-container-copy']}
                  onClick={() => {
                    navigator.clipboard.writeText(`${unitType?.urlLink}`), setIsCopy(!isCopy);
                  }}
                >
                  <span>
                    {' '}
                    <FileCopyIcon />
                    {isCopy && (
                      <span
                        className={styles['edit-unit-type-container__copy-container-talkbubble']}
                      >
                        {isCopy ? 'copied' : 'copy'}
                      </span>
                    )}
                  </span>
                </button>
              </div>
            </h1>
            {unitType && (
              <>
                <div className={styles['edit-unit-type-container__header__channel-sync']}>
                  <div className={styles['edit-unit-type-container__header__channel-sync__header']}>
                    Channel Manager
                  </div>
                  <div
                    className={styles['edit-unit-type-container__header__channel-sync__wrapper']}
                  >
                    <div
                      className={`${
                        styles['edit-unit-type-container__header__channel-sync__wrapper__image']
                      } ${
                        !unitType.beds ||
                        unitType.beds.length === 0 
                        // ||
                        // (unitType.fees && unitType.fees.findIndex((fee) => !fee.cmFeeId) > -1)
                          ? styles[
                              'edit-unit-type-container__header__channel-sync__wrapper__image--disabled'
                            ]
                          : ''
                      }`}
                    >
                      {syncingChannelData ? (
                        <img alt="upload-data" src={`${PUBLIC_PATH}/assets/gif/reload.gif`} />
                      ) : (
                        <button
                          className={
                            !unitType.beds ||
                            unitType.beds.length === 0 
                            // ||
                            // (unitType.fees && unitType.fees.findIndex((fee) => !fee.cmFeeId) > -1)
                              ? styles['edit-unit-type-container__header__channel-sync--disabled']
                              : ''
                          }
                          disabled={
                            !unitType.beds ||
                            unitType.beds.length === 0
                            //  ||
                            // (unitType.fees && unitType.fees.findIndex((fee) => !fee.cmFeeId) > -1)
                          }
                          onClick={() => {
                            handleChannelManagerSync();
                          }}
                        >
                          <Tooltip
                            title={
                              <span className={styles['edit-unit-type-container__tooltip']}>
                                {!unitType.beds ||
                                unitType.beds.length === 0 
                                // ||
                                // (unitType.fees &&
                                //   unitType.fees.findIndex((fee) => !fee.cmFeeId) > -1)
                                  ? 'Please make sure beds and Channel Manager Fees are set correctly'
                                  : ''}
                              </span>
                            }
                          >
                            <CloudUploadIcon color="inherit" fontSize="inherit" />
                          </Tooltip>
                        </button>
                      )}
                    </div>
                    <div
                      className={
                        styles['edit-unit-type-container__header__channel-sync__wrapper__content']
                      }
                    >
                      <div>
                        <label>Updated By: </label>
                        <p>{unitType.cmLastModifiedByUsername}</p>
                        <label>Updated At: </label>
                        <p>
                          {unitType.cmLastModifiedAt
                            ? format(unitType.cmLastModifiedAt)
                            : 'NOT AVAILABLE'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['edit-unit-type-container__header__listed']}>
                  <div className={styles['edit-unit-type-container__header__listed__header']}>
                    Controls
                  </div>
                  <FormControlLabel
                    control={
                      <OrangeSwitch
                        disabled={loadingData || disabledListSwitcher}
                        checked={unitType.isListed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          handleUpdateUnitType({
                            id: unitType?.id,
                            isListed: event.target.checked
                          });
                        }}
                        name="checked"
                        color="primary"
                      />
                    }
                    label={unitType.isListed ? 'Published' : 'Not Published'}
                  />
                  <FormControlLabel
                    control={
                      <OrangeSwitch
                        disabled={loadingData || disabledListSwitcher}
                        checked={unitType.bookingMode === BookingMode.INSTANT}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          handleUpdateUnitType({
                            id: unitType?.id,
                            bookingMode: event.target.checked
                              ? BookingMode.INSTANT
                              : BookingMode.REQUEST
                          });
                        }}
                        name="checked"
                        color="primary"
                      />
                    }
                    label={
                      unitType.bookingMode === BookingMode.INSTANT
                        ? 'Instant Booking'
                        : 'Request Booking'
                    }
                  />
                <FormControlLabel
                    control={
                      <OrangeSwitch
                        disabled={loadingData || disabledListSwitcher}
                        checked={unitType.isManaged}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          handleUpdateUnitType({
                            id: unitType?.id,
                            isManaged: event.target.checked
                          });
                        }}
                        name="checked"
                        color="primary"
                      />
                    }
                    label={unitType.isManaged ? 'Managed' : 'Not Managed'}

                  />
                </div>
              </>
            )}
          </div>

          <Tabs
            data={{
              tabsList: [
                { component: <></>, key: 'Listing' },
                { component: <></>, key: 'Expenses' },
                { component: <></>, key: 'Payouts' }
              ],
              defaultTab: 'Listing'
            }}
            style={{ position: TabsPosition.LEFT }}
            eventHandlers={{
              tabChangeHandler: (tabKeyValue) => {
                setTabKey(tabKeyValue);
              }
            }}
          />
          {tabKey === 'Listing' ? (
            <div className={styles['edit-unit-type-container__wrapper']}>
              <div className={styles['edit-unit-type-container__wrapper__row']}>
                <EditGeneralInfo
                  data={{
                    unitType,
                    unitTypeGroupsList
                  }}
                  config={{
                    isLoading: loadingData
                  }}
                  eventHandlers={{
                    submitHandler: (updatedData) => {
                      handleUpdateUnitType(updatedData);
                    }
                  }}
                />
                <div className={styles['edit-unit-type-container__wrapper__row--right']}>
                  <LandLordInfo
                    data={{
                      landlordName: unitType?.landlordUsername || '',
                      landlordId: unitType?.landlordId || '',
                      landlordEmail: unitType?.landlordEmail || '',
                      landlordPhone: unitType?.landlordPhone || '',
                      landlordShare: unitType?.landlordShare || 0,
                      propertyId: unitType?.propertyId || '',
                      payouts: payouts
                        .filter((p) => p.landlordId === unitType?.landlordId)
                        .map((p) => p.id)
                    }}
                    eventHandlers={{
                      addLandLord: (payoutstoUpdate: string[]) => addLandLord(payoutstoUpdate),
                      updateLandlordShare: (landlordShare: number) =>
                        handleUpdateUnitType({ landlordShare }),
                      deleteLandLord: () => deleteLandLord()
                    }}
                  />
                  <PartnerInfo
                    data={{
                      partnerName: unitType?.partnerUsername || '',
                      partnerId: unitType?.partnerId || '',
                      partnerEmail: unitType?.partnerEmail || '',
                      partnerPhone: unitType?.partnerPhone || '',
                      partnerShare: unitType?.partnerShare || 0,
                      propertyId: unitType?.propertyId || ''
                    }}
                    eventHandlers={{
                      addPartner: () => addPartner(),
                      updatePartnerShare: (partnerShare: number) =>
                        handleUpdateUnitType({ partnerShare }),
                      deletePartner: () => deletePartner()
                    }}
                  />

                  <EditFinancialInfo
                    data={{
                      unitPrice: unitType?.unitPrice || 0,
                      minStayDuration: unitType?.minStayDuration || 0,
                      maxStayDuration: unitType?.maxStayDuration || 0,
                      downPayment: unitType?.downPayment || 0,
                      insurance: unitType?.insurance || 0,
                      reservationMode: unitType?.reservationMode || ReservationMode.MONTHLY
                    }}
                    eventHandlers={{
                      submitHandler: (updatedData) => {
                        if (updatedData.reservationMode === ReservationMode.MONTHLY) {
                          updatedData = {
                            ...updatedData,
                            customPrices: []
                          };
                        } else if (updatedData.reservationMode === ReservationMode.DAILY) {
                          updatedData = {
                            ...updatedData,
                            downPayment: 0
                          };
                        }
                        handleUpdateUnitType(updatedData);
                      }
                    }}
                  />
                  {unitType?.reservationMode === 'daily' && (
                    <CustomPrice
                      data={{
                        basePrice: unitType.unitPrice || 0,
                        customPrice: unitType?.customPrices || []
                      }}
                      eventHandlers={{
                        updateCustomPrice: (customPrices: CustomPriceModel[]) =>
                          handleUpdateUnitType({ customPrices })
                      }}
                    />
                  )}
                </div>
              </div>
              <EditPlanInfo
                data={{
                  ratePlans: unitType?.ratePlans || [],
                  fees: unitType?.fees || [],
                  reservationMode: unitType?.reservationMode || ReservationMode.MONTHLY
                }}
                eventHandlers={{
                  submitHandler: (updatedData) => {
                    handleUpdateUnitType(updatedData);
                  }
                }}
              />
              {unitType && (
                <EditVisuals
                  data={{
                    roomTypePhotos: unitType?.roomTypePhotos,
                    showRooms: unitType?.showRooms,
                    id: unitType?.id || ''
                  }}
                  eventHandlers={{
                    submitImageHandler: uploadImageHandler,
                    deleteImageHandler: handleDeleteImageHandler,
                    submitShowroomImageHandler: uploadShowRoomImageHandler,
                    deleteShowroomImageHandler: handleDeleteShowroomImageHandler,
                    updateImageOrder: (images) => handleUpdateUnitType({ roomTypePhotos: images })
                  }}
                />
              )}
              <SelectAmenities
                data={{
                  amenitiesList: amenitiesList || [],
                  selectedAmenities: unitType?.amenities || []
                }}
                config={{ isLoading: loadingAmenities }}
                eventHandlers={{
                  selectAmenity: (amenity) => {
                    if (unitType && unitType.amenities) {
                      handleUpdateUnitType({ amenities: [...unitType.amenities, amenity] });
                    }
                  },
                  unselectAmenity: (amenityId) => {
                    if (unitType && unitType.amenities) {
                      const filteredAmenitiesList = unitType.amenities.filter(
                        (item) => item.id !== amenityId
                      );
                      handleUpdateUnitType({
                        amenities: filteredAmenitiesList
                      });
                    }
                  }
                }}
              />
              <Rooms
                data={{ units: unitType?.units || [] }}
                eventHandlers={{
                  submitHandler: (data) => {
                    handleUpdateUnitType({
                      units: unitType?.units?.map((unit) => {
                        if (unit.unitId === data.unitId) {
                          return data;
                        }
                        return unit;
                      })
                    });
                  },
                  submitNewHandler: (data) => {
                    unitType?.units?.push(data);
                    handleUpdateUnitType({
                      units: unitType?.units
                    });
                  }
                }}
              ></Rooms>
            </div>
          ) : tabKey === 'Expenses' ? (
            <ExpensesListContainer
              expenses={expenses}
              unitType={unitType}
              addExpenseHandler={(expense) => {
                createExpenses(expense).then((data) => {
                  getUnitDetails(String(expense.unitTypeId), getModuleName());
                });
              }}
              deleteExpenseHandler={(expense) => {
                deleteExpense(expense.id).then((data) => {
                  getUnitDetails(String(expense.unitTypeId), getModuleName());
                });
              }}
            />
          ) : (
            <PayoutsListContainer
              payouts={payouts}
              unitType={unitType}
              addPayoutHandler={(payout) => {
                createPayouts(payout, payout.file).then(() => {
                  searchPayout({ unitTypeId: Number(unitType?.id) || 0 });
                });
              }}
              deletePayoutHandler={(payout) => {
                deletePayout(payout.id).then(() => {
                  searchPayout({ unitTypeId: Number(unitType?.id) || 0 });
                });
              }}
            />
          )}
        </>
      )}
      {showAssignUserModal && (
        <AssignLandlordModal
          config={{ showModal: showAssignUserModal }}
          eventHandlers={{
            closeHandler: () => {
              setShowAssignUserModal(false);
            },
            chooseUser: (unitTypeModel: UnitTypeUiModel) => {
              unitTypeModel.payoutsToUpdate = payoutstoUpdate;
              handleUpdateUnitType(unitTypeModel);
              setShowAssignUserModal(false);
            }
          }}
        />
      )}

      {showAssignPartnerModal && (
        <AssignPartnerModal
          config={{ showModal: showAssignPartnerModal }}
          eventHandlers={{
            closeHandler: () => {
              setShowAssignPartnerModal(false);
            },
            chooseUser: (unitTypeModel: UnitTypeUiModel) => {
              handleUpdateUnitType(unitTypeModel);
              setShowAssignPartnerModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(EditUnitType);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
