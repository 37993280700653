/**
 * UserRole enum to define survey types
 */
export enum UserRole {
  VISITOR = 'visitor',
  ONLINE_USER = 'online_user',
  SUPER_ADMIN = 'superAdmin',
  RESERVATION_MANAGER = 'reservationManager',
  RESERVATION_AGENT = 'reservationAgent',
  E_COMMERCE = 'eCommerce',
  GUEST = 'guest',
  LANDLORD = 'landlord',
  PARTNER_MANAGER = 'partnerManager',
  FINANCE_MANAGER = 'financeManager',
  FINANCE_SPECIALIST = 'financeSpecialist',
  ADMIN = 'admin',
  HOUSE_KEEPING_MANAGER = 'houseKeepingManager',
  ECOMMERCE_SPECIALIST = 'eCommerceSpecialist',
  ECOMMERCE_SENIOR_SPECIALIST = 'eCommerceSeniorSpecialist',
  SALES_SPECIALIST = 'salesSpecialist',
  COMMERCIAL_MANAGER = 'commercialManager',
  HOSPITALITY_MANAGER = 'hospitalityManager',
  RESERVATIONS = 'reservations',
  HOSPITALITY_AGENT = 'hospitalityAgent',
  OPERATION_MANAGER = 'operationManager',
  HOUSE_KEEPING = 'houseKeeping'
}
