export enum UserType {
  VISITOR = 'visitor',
  ONLINE_USER = 'online_user',
  SUPER_ADMIN = 'superAdmin',
  PROPERTY_MANAGER = 'propertyManager',
  GUEST = 'guest',
  ADMIN = 'admin',
  LANDLORD = 'landlord',
  PARTNER_MANAGER = 'partnerManager',
  RESERVATION_MANAGER = 'reservationManager',
  RESERVATION_AGENT = 'reservationAgent',
  HOSPITALITY_MANAGER = 'hospitalityManager',
  RESERVATIONS = 'reservations',
  HOSPITALITY_AGENT = 'hospitalityAgent'
}
