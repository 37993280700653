import React, { useEffect, useState } from 'react';
import styles from './Kpis.container.module.scss';
import Select, { ValueType, components } from 'react-select';
import Button, {
  ButtonSize,
  ButtonType
} from '../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../shared/hooks/window-resizer/window-resizer.hook';
import SalesRevenueChart from '../charts/sales-revenue-chart';
import OccupancyRateChart from '../charts/occupancy-rate-chart';
import ADRChart from '../charts/ADR-chart';
import RevenuePerAvailableRoomChart from '../charts/revenue-per-available-room-chart';
import RevenueSourcesPerAreaChart from '../charts/revenue-sources-per-area-chart';
import InternationalVsLocalGuestChart from '../charts/international-vs-local-guest-chart';
import SourceOfBookingChart from '../charts/source-of-booking-chart';
import { store } from 'react-notifications-component';
import { ErrorInfo, isErrorInfo } from '../../shared/interfaces/error-info.interface';
import { getAllUnitTypes, getUnitTypeRooms } from '../../shared/services/data/unit-type.data';
import { getModuleName } from '../shared/services/Kpis.service';
import {
  RoomsUiModelResponse,
  UnitTypeUiModelResponse
} from '../../shared/ui-models/unit-type.ui-model';
import { getAllProperties } from '../../PropertyModule/shared/services/data.service';
import { PropertyUiModel } from '../../shared/ui-models/property.ui-model';
import {
  getAllAreas,
  getAllUnitTypeGroups,
  getAllUsers
} from '../../shared/services/data/lookups.data';
import { AreaUiModel } from '../../shared/ui-models/area.ui-model';
import { KPIChartsSearch } from '../../shared/models/kpis.model';
import { UserUIModel } from '../../shared/ui-models/user.ui-model';
import { UserRole } from '../../shared/enums/user-role.enum';
import { UnitTypeGroupUiModel } from '../../shared/ui-models/unit-type-group.ui-model';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import TotalRevenueChart from '../charts/total-revenue-chart';
import { OccupancyAndAverageDailyRateDataProvider } from '../shared/services/OccupancyAndAverageDailyRate-provider';
import { connect } from 'react-redux';
import { RootState } from '../../shared/redux/reducers';
import { UnauthorizedChart } from '../charts/unauthorized-chart/unauthorized-chart.component';

interface KpisContainerComponentProps {
  userRole: UserRole;
  userRoles: UserRole[];
}

/**
 * function KpisContainer components
 */

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '1.5rem' // Adjust the font size as needed
  }
})(Tooltip);

const KpisContainer: React.FC<KpisContainerComponentProps> = ({ userRole, userRoles }) => {
  const isMobileView = useWindowResizer();
  const [roomsOptions, setRoomsOptions] = useState<{ value: string; label: string }[]>([]);
  const [allPropertiesList, setAllPropertyList] = useState<{ value: string; label: string }[]>([]);
  const [allLandlordOptions, setAlLandlordOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [areaOptions, setAreaOptions] = useState<{ value: string; label: string }[]>([]);
  const [unitTypeGroupOptions, setUnitTypeGroupOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  //to be dynamic based on the current months get last 18 months
  const [monthOptions, setMonthOptions] = useState<{ value: string; label: string }[]>([]);
  const [searchPayload, setSearchPayload] = useState<KPIChartsSearch>({ query: {} });
  const [applyFilter, setApplyFilters] = useState<KPIChartsSearch>({});
  const [area, setArea] = useState<{ value: string; label: string }[] | null>(null);
  const [unit, setUnit] = useState<{ value: string; label: string }[] | null>(null);
  const [unitGroup, setUnitGroup] = useState<{ value: string; label: string }[] | null>(null);
  const [project, setProject] = useState<{ value: string; label: string }[] | null>(null);
  const [room, setRoom] = useState<{ value: string; label: string }[] | null>(null);
  const [currency, setCurrency] = useState<{ value: string; label: string } | null>(null);
  const [nationality, setNationality] = useState<{ value: string; label: string } | null>(null);
  const [OTA, SetOTA] = useState<{ value: string; label: string } | null>(null);
  const [landlord, SetLandlord] = useState<{ value: string; label: string }[] | null>(null);
  const [months, setMonths] = useState<{ value: string; label: string }[] | null>(null);

  useEffect(() => {
    const options = [];
    const date = new Date();
    date.setMonth(date.getMonth() - 12);

    for (let i = 0; i < 15; i++) {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const label =
        date.toLocaleString('default', { month: 'short' }) + ' ' + String(year).slice(2);
      options.push({ value: `${month} - ${year}`, label: label });
      date.setMonth(date.getMonth() + 1);
    }
    console.log(options);
    setMonthOptions(options);
  }, []);

  const getAllRooms = () => {
    getUnitTypeRooms(getModuleName()).then((response: RoomsUiModelResponse[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setRoomsOptions(response);
      } else {
        store.addNotification({
          id: 'get-all-rooms-failed',
          title: 'error while getting rooms',
          message: 'failed loading unit type rooms',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };

  const getAllProps = () => {
    getAllProperties().then((response: PropertyUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAllPropertyList(
          response.map((property) => ({ value: property.id || '', label: property.name || '' }))
        );
      }
    });
  };

  const getAreas = () => {
    getAllAreas(getModuleName()).then((response: AreaUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAreaOptions(response.map((area) => ({ value: area.id || '', label: area.name })));
      }
    });
  };

  const getUnitTypes = () => {
    getAllUnitTypes(getModuleName(), 0, -1, {}).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setUnitTypeOptions(
            response?.unitList?.map((unitType) => ({
              value: unitType.id,
              label: unitType.name || ''
            }))
          );
        }
      }
    );
  };

  const getAllLandLords = () => {
    getAllUsers(getModuleName()).then((response: UserUIModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAlLandlordOptions(
          response
            .filter(
              (user) =>
                user.role === UserRole.LANDLORD ||
                user.roles?.filter((r) => r == UserRole.LANDLORD).length != 0
            )
            .map((user) => ({
              value: user.id as string,
              label: user.name || ''
            }))
        );
      }
    });
  };
  const getAllUnitTypeGroup = () => {
    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupOptions(
          response.map((unitGroup) => ({
            value: unitGroup.id as string,
            label: unitGroup.name
          }))
        );
      }
    });
  };

  const isPermited = (permittedList: UserRole[]) => {
    return (
      permittedList.indexOf(userRole) > -1 ||
      permittedList.some((item) => userRoles?.includes(item))
    );
  };

  useEffect(() => {
    getAllRooms();
    getAllProps();
    getAreas();
    getUnitTypes();
    getAllLandLords();
    getAllUnitTypeGroup();

    return () => {
      store.removeNotification('get-all-rooms-failed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MultiValue = (props) => {
    const maxToShow = props.selectProps.maxToShow;
    const length = props.getValue().length;
    const shouldBadgeShow = length > maxToShow;
    const displayLength = length - maxToShow;

    // Create a tooltip title with the selected items
    const tooltipTitle = props
      .getValue()
      .map((item) => item.label)
      .join(', ');

    if (props.index < maxToShow) {
      return <components.MultiValue {...props} />;
    } else if (props.index === maxToShow) {
      return (
        <CustomTooltip title={tooltipTitle}>
          <div className="root">
            {shouldBadgeShow && `+ ${displayLength} item${length !== 1 ? 's' : ''} selected`}
          </div>
        </CustomTooltip>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles['kpis']}>
      <div className={styles['kpis-filters-container']}>
        <div className={styles['kpis-filters-container__filters']}>
          <div className={styles['kpis-filters-container__search']}>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={unitGroup}
                options={unitTypeGroupOptions}
                isMulti
                isSearchable={true}
                maxToShow={5}
                placeholder="Unit type group"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  setUnitGroup(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      unitTypeGroupIds: value?.length
                        ? value.map((option) => option.value)
                        : undefined
                    }
                  });
                }}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={unit}
                options={unitTypeOptions}
                isMulti
                isSearchable={true}
                maxToShow={3}
                placeholder="Unit type"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  setUnit(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      unitTypeIds: value?.length
                        ? value.map((option) => Number(option.value))
                        : undefined
                    }
                  });
                }}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={area}
                options={areaOptions}
                isMulti
                isSearchable
                placeholder="Area"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  setArea(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      areaIds: value?.length ? value.map((option) => option.value) : undefined
                    }
                  });
                }}
                maxToShow={5}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={months}
                isMulti
                isSearchable
                options={monthOptions}
                placeholder="Months"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  setMonths(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      date: value?.length ? value.map((option) => option.value) : undefined
                    }
                  });
                }}
                maxToShow={7}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={project}
                options={allPropertiesList}
                isMulti
                isSearchable
                placeholder="Project"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  setProject(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      propertyIds: value?.length ? value.map((option) => option.value) : undefined
                    }
                  });
                }}
                maxToShow={5}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={landlord}
                options={allLandlordOptions}
                isMulti
                isSearchable
                placeholder="LandLord"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  SetLandlord(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      landlordIds: value?.length ? value.map((option) => option.value) : undefined
                    }
                  });
                }}
                maxToShow={5}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={room}
                options={roomsOptions}
                isMulti
                isSearchable={true}
                placeholder="Room"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
                  setRoom(value ? value : null);
                  setSearchPayload({
                    query: {
                      ...searchPayload.query,
                      roomIds: value?.length ? value.map((option) => option.value) : undefined
                    }
                  });
                }}
                maxToShow={3}
                components={{ MultiValue }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={currency}
                options={[
                  { label: 'EGP', value: '1' },
                  { label: 'USD', value: '2' }
                ]}
                placeholder="Currency"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                  setCurrency(value ? value : { value: '', label: '' });
                  setSearchPayload({
                    query: { ...searchPayload.query, currency: Number(value?.value) }
                  });
                }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={nationality}
                options={[
                  { label: 'Egyptians', value: '1' },
                  { label: 'others', value: '2' }
                ]}
                placeholder="Nationality"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                  setNationality(value ? value : { value: '', label: '' });
                  setSearchPayload({
                    query: { ...searchPayload.query, nationality: Number(value?.value) }
                  });
                }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__form-field']}>
              <Select
                value={OTA}
                options={[
                  { label: 'Birdnest', value: '1' },
                  { label: "OTA's", value: '2' }
                ]}
                placeholder="Source of booking"
                className={styles['kpis-filters-container__search__form-field__select']}
                onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                  SetOTA(value ? value : { value: '', label: '' });
                  setSearchPayload({ query: { ...searchPayload.query, source: value?.value } });
                }}
              />
            </div>
            <div className={styles['kpis-filters-container__search__actions']}>
              <Button
                className={styles['kpis-filters-container__search__button']}
                onClick={() => {
                  setApplyFilters(searchPayload);
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Apply
              </Button>
              <Button
                className={styles['kpis-filters-container__search__button']}
                type={ButtonType.gray}
                onClick={() => {
                  setApplyFilters({});
                  setSearchPayload({ query: {} });
                  setUnit(null);
                  setArea(null);
                  setProject(null);
                  setRoom(null);
                  setNationality(null);
                  SetOTA(null);
                  setCurrency(null);
                  SetLandlord(null);
                  setMonths(null);
                  setUnitGroup(null);
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      </div>
      <OccupancyAndAverageDailyRateDataProvider monthsCount={11} payload={applyFilter}>
        <>
          <div>
            {isPermited([UserRole.ADMIN, UserRole.FINANCE_MANAGER, UserRole.COMMERCIAL_MANAGER]) ? (
              <TotalRevenueChart query={applyFilter} />
            ) : (
              <UnauthorizedChart chartName="Total revenue" />
            )}
          </div>
          <div className={styles['kpis__charts']}>
            {isPermited([
              UserRole.ADMIN,
              UserRole.FINANCE_MANAGER,
              UserRole.COMMERCIAL_MANAGER,
              UserRole.ECOMMERCE_SENIOR_SPECIALIST,
              UserRole.HOSPITALITY_MANAGER,
              UserRole.RESERVATION_MANAGER
            ]) ? (
              <OccupancyRateChart query={applyFilter} />
            ) : (
              <UnauthorizedChart chartName="Occupancy Rate" />
            )}

            {isPermited([
              UserRole.ADMIN,
              UserRole.FINANCE_MANAGER,
              UserRole.COMMERCIAL_MANAGER,
              UserRole.ECOMMERCE_SENIOR_SPECIALIST
            ]) ? (
              <ADRChart query={applyFilter} />
            ) : (
              <UnauthorizedChart chartName="ADR" />
            )}
          </div>
          <div className={styles['kpis__charts']}>
            {isPermited([UserRole.ADMIN, UserRole.FINANCE_MANAGER, UserRole.COMMERCIAL_MANAGER]) ? (
              <RevenuePerAvailableRoomChart query={applyFilter} />
            ) : (
              <UnauthorizedChart chartName="Revenue Per Available Room" />
            )}
            {isPermited([UserRole.ADMIN, UserRole.FINANCE_MANAGER, UserRole.COMMERCIAL_MANAGER]) ? (
              <SalesRevenueChart query={applyFilter} />
            ) : (
              <UnauthorizedChart chartName="Sales Revenue" />
            )}
          </div>
          <div className={styles['kpis__charts']}>
            <div className={`${styles['kpis__charts']} ${styles['kpis__charts--nested']}`}>
              {isPermited([
                UserRole.ADMIN,
                UserRole.FINANCE_MANAGER,
                UserRole.COMMERCIAL_MANAGER,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.HOSPITALITY_MANAGER,
                UserRole.RESERVATION_MANAGER
              ]) ? (
                <RevenueSourcesPerAreaChart query={applyFilter} />
              ) : (
                <UnauthorizedChart chartName="Revenue Sources Per Area" />
              )}
              {isPermited([
                UserRole.ADMIN,
                UserRole.FINANCE_MANAGER,
                UserRole.COMMERCIAL_MANAGER,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.HOSPITALITY_MANAGER,
                UserRole.RESERVATION_MANAGER
              ]) ? (
                <InternationalVsLocalGuestChart query={applyFilter} />
              ) : (
                <UnauthorizedChart chartName="International Vs LocalGuest" />
              )}
            </div>
            {isPermited([
              UserRole.ADMIN,
              UserRole.FINANCE_MANAGER,
              UserRole.COMMERCIAL_MANAGER,
              UserRole.ECOMMERCE_SENIOR_SPECIALIST,
              UserRole.HOSPITALITY_MANAGER,
              UserRole.RESERVATION_MANAGER
            ]) ? (
              <SourceOfBookingChart query={applyFilter} />
            ) : (
              <UnauthorizedChart chartName="Source Of Booking" />
            )}
          </div>
        </>
      </OccupancyAndAverageDailyRateDataProvider>
    </div>
  );
};

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    userRole: state.auth.role,
    userRoles: state.auth.roles,
    userEmail: state.auth.email,
    userNotifications: state.auth.userNotifications
  };
}

export default connect(mapStateToProps)(KpisContainer);
