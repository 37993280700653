import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Route, useHistory } from 'react-router';
import { RootState } from '../../redux/reducers/';
import GlobalHeader from '../../components/group-ui/global-header/global-header.component';
import { ProtectedRoute } from '../protected-route/protected-route.component';
import { APP_ROUTES } from '../../config/routes-config';
import { store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { RouteConfig } from '../../interfaces/routes-config.interface';
import { cleanupUserData } from '../../services/helpers/auth-helper/auth-helper.service';
import { SideMenu } from '../../components/group-ui/side-menu/side-menu.component';
import { UserNotificationsModel } from '../../models/user-notifications.model';
import { UserRole } from '../../enums/user-role.enum';

/**
 * interface DefaultLayoutComponentProps
 */
interface DefaultLayoutComponentProps {
  userEmail: string;
  userRole: UserRole;
  userRoles: UserRole[];
  userNotifications?: UserNotificationsModel | undefined;
  logoutHandler: () => void;
}

/**
 * functional component to render child route in Default layout
 * @param childern react element to be injected in component
 * @param userEmail user email
 * @param logoutHandler to handle user logout action
 */
const DefaultLayoutComponent: React.FC<DefaultLayoutComponentProps> = ({
  children,
  userEmail,
  userRole,
  userRoles,
  userNotifications,
  logoutHandler
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <main id="body-content" className="body-content">
        <SideMenu
          userEmail={userEmail}
          userRole={userRole}
          userRoles={userRoles}
          userNotification={userNotifications}
          logoutHandler={logoutHandler}
          toggleSideMenu={openMenu}
        />
        <GlobalHeader
          userEmail={userEmail}
          logoutHandler={logoutHandler}
          toggleMobileMenu={() => {
            setOpenMenu(false);
            // to handle icon click after side menu were closed by clicking outside
            setTimeout(() => {
              setOpenMenu(true);
            }, 0);
          }}
        />
        <div className={'body-content__container'}>{children}</div>
      </main>
    </>
  );
};

/**
 * interface DefaultRouteProps
 */
interface DefaultRouteProps {
  component: React.ComponentType<any>;
  path: string;
  authenticationRequired: boolean;
  permittedList?: UserRole[];
  userEmail: string;
  userRole: UserRole;
  userRoles: UserRole[];
  userNotifications: UserNotificationsModel | undefined;
  isAuthenticated: boolean;
}

/**
 * functional component to handle rendering routes in default layout
 * @param component react component to be rendered
 * @param userEmail user email
 * @param isAuthenticated user authentication status
 * @param authenticationRequired route config whether route will be protected or not
 */
const DefaultRoute: React.FC<DefaultRouteProps> = ({
  component: Component,
  userEmail,
  permittedList,
  userRole,
  userRoles,
  userNotifications,
  isAuthenticated,
  authenticationRequired,
  ...rest
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const logoutUser = () => {
    store.addNotification({
      title: t('SHARED.HEADER.NOTIFICATIONS.LOGOUT_SUCCESS.TITLE'),
      message: t('SHARED.HEADER.NOTIFICATIONS.LOGOUT_SUCCESS.MESSAGE'),
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    setTimeout(() => {
      cleanupUserData();
      history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH);
    }, 1500);
  };
  return authenticationRequired ? (
    <ProtectedRoute
      userRole={userRole}
      userRoles={userRoles}
      isAuthenticated={isAuthenticated}
      permittedList={permittedList}
      component={(matchProps) => (
        <DefaultLayoutComponent
          userRole={userRole}
          userRoles={userRoles}
          userEmail={userEmail}
          logoutHandler={logoutUser}
          userNotifications={userNotifications}
        >
          <Component {...matchProps} />
        </DefaultLayoutComponent>
      )}
    />
  ) : (
    <Route
      {...rest}
      render={(matchProps) => (
        <DefaultLayoutComponent
          userRole={userRole}
          userRoles={userRoles}
          userEmail={userEmail}
          logoutHandler={logoutUser}
          userNotifications={userNotifications}
        >
          <Component {...matchProps} />
        </DefaultLayoutComponent>
      )}
    />
  );
};

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    userRole: state.auth.role,
    userRoles: state.auth.roles,
    userEmail: state.auth.email,
    userNotifications: state.auth.userNotifications
  };
}

export default connect(mapStateToProps)(DefaultRoute);
