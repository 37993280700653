import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './home.module.scss';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { RevenueChartComponent } from './components/revenue-chart/revenue-chart.component';
import { OccupancyRateChartComponent } from './components/occupancy-rate-chart/occupancy-rate-chart.component';
import { GlobalCalendarComponent } from './components/global-calendar/global-calendar.component';
import { ReservationsUpdates } from './components/reservations-updates/reservations-updates.component';
import { withAuthorization } from '../../../shared/services/auth/with-authorization/with-authorization.component';
import { UserRole } from '../../../shared/enums/user-role.enum';

import { PendingActionsComponent } from './components/pending-actions/pending-actions.component';

import _ from 'lodash';
import { DoubleBookings } from './components/double-booking/double-booking.component';

export type HomeContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component HomeContainer
 * @param {HomeContainerProps} holding question text
 */
export const HomeContainer: React.FC<HomeContainerProps> = ({ isAuthenticated }) => {
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('dashboard');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['home-container']}>
      <div className={styles['home-container__title']}>{t('DASHBOARD_MODULE.HOME.TITLE')}</div>

      <PendingSection />
      <DoubleBookingSummary />
      <ReservationsSummary />

      <div className={styles['home-container__charts-split__chart']}>
        <GlobalCalendar />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(HomeContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}

// const OccupancyRate = withAuthorization(
//   [UserRole.ADMIN, UserRole.E_COMMERCE, UserRole.FINANCE_MANAGER],
//   true
// )(() => (
//   <div
//     className={`${styles['home-container__charts-split__chart']} ${styles['home-container__charts-split--half']}`}
//   >
//     <OccupancyRateChartComponent />
//   </div>
// ));

// const MonthlyRevenue = withAuthorization(
//   [UserRole.ADMIN, UserRole.E_COMMERCE, UserRole.FINANCE_MANAGER],
//   true
// )(() => (
//   <div
//     className={`${styles['home-container__charts-split__chart']} ${styles['home-container__charts-split--half']}`}
//   >
//     <RevenueChartComponent />
//   </div>
// ));

const PendingSection = withAuthorization(
  [UserRole.E_COMMERCE,
  UserRole.ECOMMERCE_SPECIALIST,
  UserRole.ECOMMERCE_SENIOR_SPECIALIST,
  UserRole.SALES_SPECIALIST,
  UserRole.ADMIN,
  UserRole.FINANCE_MANAGER,
  UserRole.FINANCE_SPECIALIST,
  UserRole.RESERVATION_MANAGER,
  UserRole.RESERVATION_AGENT,
  UserRole.RESERVATIONS,
  UserRole.HOSPITALITY_AGENT,
  UserRole.HOSPITALITY_MANAGER,
  UserRole.HOUSE_KEEPING_MANAGER,
  UserRole.OPERATION_MANAGER,
  UserRole.COMMERCIAL_MANAGER],
  true
)(() => <PendingActionsComponent />);

const ReservationsSummary = withAuthorization(
  [UserRole.E_COMMERCE,
  UserRole.ECOMMERCE_SPECIALIST,
  UserRole.ECOMMERCE_SENIOR_SPECIALIST,
  UserRole.SALES_SPECIALIST,
  UserRole.ADMIN,
  UserRole.FINANCE_MANAGER,
  UserRole.FINANCE_SPECIALIST,
  UserRole.RESERVATION_MANAGER,
  UserRole.RESERVATION_AGENT,
  UserRole.RESERVATIONS,
  UserRole.HOSPITALITY_AGENT,
  UserRole.HOSPITALITY_MANAGER,
  UserRole.HOUSE_KEEPING_MANAGER,
  UserRole.OPERATION_MANAGER,
  UserRole.COMMERCIAL_MANAGER],
  true
)(() => <ReservationsUpdates />);

const DoubleBookingSummary = withAuthorization(
  [UserRole.E_COMMERCE,
  UserRole.ECOMMERCE_SPECIALIST,
  UserRole.ECOMMERCE_SENIOR_SPECIALIST,
  UserRole.SALES_SPECIALIST,
  UserRole.ADMIN,
  UserRole.FINANCE_MANAGER,
  UserRole.FINANCE_SPECIALIST,
  UserRole.RESERVATION_MANAGER,
  UserRole.RESERVATION_AGENT,
  UserRole.RESERVATIONS,
  UserRole.HOSPITALITY_AGENT,
  UserRole.HOSPITALITY_MANAGER,
  UserRole.HOUSE_KEEPING_MANAGER,
  UserRole.OPERATION_MANAGER,
  UserRole.COMMERCIAL_MANAGER],
  true
)(() => <DoubleBookings />);

const GlobalCalendar = withAuthorization(
  [UserRole.E_COMMERCE,
  UserRole.ECOMMERCE_SPECIALIST,
  UserRole.ECOMMERCE_SENIOR_SPECIALIST,
  UserRole.SALES_SPECIALIST,
  UserRole.ADMIN,
  UserRole.FINANCE_MANAGER,
  UserRole.FINANCE_SPECIALIST,
  UserRole.RESERVATION_MANAGER,
  UserRole.RESERVATION_AGENT,
  UserRole.RESERVATIONS,
  UserRole.HOSPITALITY_AGENT,
  UserRole.HOSPITALITY_MANAGER,
  UserRole.HOUSE_KEEPING_MANAGER,
  UserRole.OPERATION_MANAGER,
  UserRole.COMMERCIAL_MANAGER],
  true
)(() => <GlobalCalendarComponent />);