import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './unauthorized-chart.module.scss';
import { useHistory } from 'react-router';

interface UnauthorizedChartProps {
  chartName: string;
}

/**
 * Unauthorized functional component
 */
export const UnauthorizedChart: React.FC<UnauthorizedChartProps> = ({ chartName }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={styles['unauthorized-chart ']}>
      <h1 className={styles['unauthorized-chart__title']}>Not Available: {chartName} </h1>
    </div>
  );
};
