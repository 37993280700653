import { handleActions } from 'redux-actions';
import * as Actions from '../../constants/actions';
import { UserModel } from '../../../models/user.model';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { RootState } from '..';
import { UserRole } from '../../../enums/user-role.enum';
import { UserNotificationsModel } from '../../../models/user-notifications.model';

export interface AuthStoreState extends UserModel {
  fetching: boolean;
  error: ErrorInfo | null;
  userNotifications: UserNotificationsModel | undefined;
  accessToken: string;
  tokenExpiresIn: number;
  permissions: string[];
  refreshToken: string;
  refreshTokenExpiresIn: number;
}

export const initialState: AuthStoreState = {
  error: null,
  fetching: false,
  tokenExpiresIn: 0,
  refreshTokenExpiresIn: 0,
  userNotifications: undefined,
  userId: '',
  email: '',
  permissions: [],
  accessToken: '',
  refreshToken: '',
  role: UserRole.PROPERTY_MANAGER,
  roles: [UserRole.PROPERTY_MANAGER]
};

export const AuthSelectors = {
  isLoggedIn: (state: RootState) => state.auth.accessToken
};

export default handleActions<AuthStoreState>(
  {
    [Actions.REGISTER_USER_REQUEST]: (state, action) => {
      const { email } = action.payload;
      return {
        ...state,
        email,
        error: null,
        fetching: true
      };
    },
    [Actions.REGISTER_USER_SUCCESS]: (state, action) => {
      return {
        ...state,
        ...action.payload,
        error: null,
        fetching: false,
        userLoaded: true
      };
    },
    [Actions.REGISTER_USER_FAILURE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
        fetching: false,
        userLoaded: true
      };
    },
    [Actions.USER_DATA_SUCCESS]: (state, action) => {
      const {
        email,
        userId,
        accessToken,
        tokenExpiresIn,
        refreshToken,
        refreshTokenExpiresIn,
        role,
        roles,
        permissions
      } = action.payload;
      return {
        ...state,
        email,
        userId,
        accessToken,
        tokenExpiresIn,
        refreshToken,
        refreshTokenExpiresIn,
        role,
        roles,
        permissions,
        fetching: false,
        userLoaded: true
      };
    },
    [Actions.USER_NOTIFICATION_SUCCESS]: (state, action) => {
      return {
        ...state,
        userNotifications: action.payload
      };
    },
    [Actions.USER_DATA_CLEANUP]: (state) => {
      return initialState;
    }
  },
  initialState
);
