import React, { useEffect, useState, useRef } from 'react';
import {
  ScheduleComponent,
  TreeViewArgs,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  ResourceDetails,
  Inject,
  TimelineViews,
  Resize,
  // DragAndDrop,
  TimelineMonth
  // Day
} from '@syncfusion/ej2-react-schedule';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from './global-calendar.module.scss';
import { ReservationModel } from '../../../../../shared/models/reservation.model';
import {
  UnitTypeUiModel,
  UnitTypeUiModelResponse
} from '../../../../../shared/ui-models/unit-type.ui-model';
import { PUBLIC_PATH, APP_ROUTES } from '../../../../../shared/config/routes-config';
import { flattenDeep, uniq } from 'lodash';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import { BlockedDatesUiModel } from '../../../../../shared/ui-models/blocked-dates.ui-model';
import { getAllReservations } from '../../../../../ReservationModule/shared/services/data.service';
import { isErrorInfo, ErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { getAllBlockingDates } from '../../../../../BlockDatesModule/shared/services/data.service';
import { ReservationStatus } from '../../../../../shared/enums/reservation-status';
import { getAllUnitTypes } from '../../../../../shared/services/data/unit-type.data';
import { getModuleName } from '../../../../shared/services/dashboard.service';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import Select, { ValueType } from 'react-select';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import { InputText } from '../../../../../shared/components/core-ui/input-text/input-text.component';
import {
  ReservationListUiModel,
  ReservationUiModel
} from '../../../../../shared/ui-models/reservation.ui-model';
import { UnitTypeGroupUiModel } from '../../../../../shared/ui-models/unit-type-group.ui-model';
import { getAllUnitTypeGroups } from '../../../../../shared/services/data/lookups.data';
import _ from 'lodash';
import { PropertyUiModel } from '../../../../../shared/ui-models/property.ui-model';
import { AreaUiModel } from '../../../../../shared/ui-models/area.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';

/**
 * functional component GlobalCalendarComponent
 * @param {GlobalCalendarComponentProps}
 */
const colors: string[] = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#006300',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#94be00',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF'
];
export const GlobalCalendarComponent: React.FC = () => {
  const [events, setEvents] = useState<Record<string, any>[]>();
  const [unitsData, setUnitsData] = useState<any[]>();
  const [sortedUnitsData, setSortedUnitsData] = useState<any[]>();
  const [guestName, setGuestName] = useState('');
  const [uniqKey, setUniqKey] = useState(Date.now);
  const [unitType, setUnitType] = useState<{ value: ''; label: '' }>({ value: '', label: '' });
  const [unitTypeGroups, setUnitTypeGroups] = useState<{ value: ''; label: '' }>({
    value: '',
    label: ''
  });
  const [projectOptions, setProjectOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  const [initialProjectOptions, setInitialProjectOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState<
    {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      areaId: string;
    }[]
  >([]);
  const [unitTypeGroupsOptions, setUnitTypeGroupsOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<UnitTypeGroupUiModel[]>([]);
  const [projectsList, setProjectsList] = useState<PropertyUiModel[]>([]);
  const [areaList, setAreaList] = useState<AreaUiModel[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [initialReservation, setInitialReservation] = useState<ReservationModel[]>();

  const isMobileView = useWindowResizer();
  const scheduleRef = useRef(null);
  const [initialUnitTypes, setInitialUnitTypes] = useState<
    {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      areaId: string;
    }[]
  >([]);
  const [areaOptions, setAreaOptions] = useState<
    { value: string; label: string; propertyId: string; userId: string; roomId: [] }[]
  >([]);
  const [InitialAreaOptions, setInitialAreaOptions] = useState<
    { value: string; label: string; propertyId: string; userId: string; roomId: [] }[]
  >([]);
  const [usersOptions, setUsersOptions] = useState<
    { value: string; label: string; roomId: []; propertyId: string; areaId: string }[]
  >([]);
  const [initialUsersOptions, setInitialUsersOptions] = useState<
    { value: string; label: string; roomId: []; propertyId: string; areaId: string }[]
  >([]);
  const [initialProjectsList, setInitialProjectsList] = useState<
    { value: string; label: string; roomId: []; userId: string; areaId: string }[]
  >([]);
  const [user, setUser] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [area, setArea] = useState<{ value: string; label: string }>({ value: '', label: '' });
  const [project, setProject] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [unitTypeSearch, setUnitTypeSearch] = useState<FormElementData<string>>({
    value: '',
    isValid: true
  });
  const [unitTypeGroup, setUnitTypeGroup] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [currentDataSelected, setCurrentDataSelected] = useState<Date>(new Date());
  const [unitTypesList, setUnitTypesList] = useState<UnitTypeUiModel[]>();
  const [lazyLoading, setLazyLoading] = useState<boolean>(false);
  const [initialUnitList, setInitialUnitList] = useState<UnitTypeUiModel[]>([]);
  const [groupOptions, setGroupOptions] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      userId: string;
    }[]
  >([]);
  const [initialGroupIdOptions, setInitialGroupIdOptions] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      userId: string;
    }[]
  >([]);
  const getReservations = (currentDate: Date) => {
    getAllReservations(
      {
        statusQuery: [
          ReservationStatus.CHECKED_IN,
          ReservationStatus.CHECKED_OUT,
          ReservationStatus.CONFIRMATION_PENDING,
          ReservationStatus.IN_HOUSE,
          ReservationStatus.CHECKED_IN_STARTED,
          ReservationStatus.PENDING_CHECK_IN
        ],
        $or: [
          {
            checkInDate: {
              $gte: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime(),
              $lte: moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
                .add(1, 'days')
                .toDate()
                .getTime()
            }
          },
          {
            checkOutDate: {
              $gte: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime(),
              $lte: moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
                .add(1, 'days')
                .toDate()
                .getTime()
            }
          },
          {
            $and: [
              {
                checkInDate: {
                  $lte: moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
                    .add(1, 'days')
                    .toDate()
                    .getTime()
                }
              },
              {
                checkOutDate: {
                  $gt: new Date(currentDate.getFullYear(), currentDate.getMonth(), +1, 0).getTime()
                }
              }
            ]
          }
        ]
      },
      1,
      0
    ).then((response: ReservationListUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setLazyLoading(false);
        if (response && response?.reservationList?.length > 0) {
          getBlockingDates({ query: { isCancelled: false } }, response?.reservationList);
        }
      }
    });
  };
  const getBlockingDates = (query: {}, reservations: ReservationUiModel) => {
    getAllBlockingDates(query).then((response: BlockedDatesUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        if (response && response.length > 0) {
          setEvents([
            ...reservations.map((item, index) => ({
              Id: index,
              Subject:
                item.status === ReservationStatus.PENDING_CHECK_IN
                  ? '[PENDING] ' + item.primaryGuestName
                  : item.primaryGuestName,
              StartTime: moment(item.checkInDate).format('MM/DD/YYYY'),
              EndTime: moment(item.checkOutDate).format('MM/DD/YYYY'),
              IsAllDay: true,
              IsBlock: true,
              id: item.roomId,
              reservationId: item.id,
              blockedDate: false
            })),
            ...response.map((item, index) => ({
              Id: index,
              Subject: `BLOCKED (${item.description})`,
              StartTime: moment(item.fromDate).format('MM/DD/YYYY'),
              EndTime: moment(item.toDate).format('MM/DD/YYYY'),
              IsAllDay: true,
              IsBlock: true,
              id: item.roomId,
              blockedDateId: item.id,
              blockedDate: true
            }))
          ]);
        }
      }
    });
  };

  const filterDataWithoutDuplicate = (
    data: UnitTypeUiModel[],
    unitGroups: UnitTypeGroupUiModel[]
  ) => {
    const groupId: {
      value: string;
      label: string;
      areaId: string;
      propertyId: string;
      userId: string;
      roomId: [];
    }[] = [];
    const area: {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      roomId: [];
    }[] = [];
    const rooms: {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      areaId: string;
    }[] = [];
    const projects: {
      value: string;
      label: string;
      roomId: [];
      userId: string;
      areaId: string;
    }[] = [];
    const users: {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
    }[] = [];
    data?.map((unit) => {
      if (unit?.areaId !== undefined && unit?.area !== undefined) {
        area.push({
          value: unit?.areaId,
          label: unit?.area,
          propertyId: unit?.propertyId,
          userId: unit?.landlordId,
          roomId: unit?.units,
          groupId: unit?.unitTypeGroupId
        });
      }
      if (unit?.units?.length > 0) {
        unit?.units?.map((room) =>
          rooms.push({
            value: room.unitId,
            label: room.shortName,
            propertyId: unit?.propertyId,
            userId: unit?.landlordId,
            areaId: unit.areaId,
            groupId: unit?.unitTypeGroupId
          })
        );
      }
      if (unit?.propertyId !== undefined && unit?.propertyName !== undefined) {
        projects.push({
          value: unit?.propertyId,
          label: unit?.propertyName,
          userId: unit?.landlordId,
          roomId: unit.units,
          areaId: unit.areaId,
          groupId: unit?.unitTypeGroupId
        });
      }
      if (unit?.landlordId !== undefined && unit?.landlordUsername !== undefined) {
        users.push({
          value: unit?.landlordId,
          label: unit?.landlordUsername,
          roomId: unit?.units,
          areaId: unit?.areaId,
          propertyId: unit?.propertyId,
          groupId: unit?.unitTypeGroupId
        });
      }
      if (unit?.unitTypeGroupId !== undefined) {
        groupId.push({
          value: unit?.unitTypeGroupId,
          label: unitGroups?.find((unitGroup) => unitGroup?.id === unit?.unitTypeGroupId)?.name,
          roomId: unit?.units,
          areaId: unit?.areaId,
          propertyId: unit?.propertyId,
          userId: unit?.landlordId
        });
      }
    });

    if (area.length > 0) {
      setAreaOptions(_.uniqBy(area, 'value'));
      setInitialAreaOptions(_.uniqBy(area, 'value'));
    }
    if (rooms.length > 0) {
      setUnitTypeOptions(_.uniqBy(rooms, 'value'));
      setInitialUnitTypes(_.uniqBy(rooms, 'value'));
    }
    if (projects.length > 0) {
      setProjectsList(_.uniqBy(projects, 'value'));
      setInitialProjectsList(_.uniqBy(projects, 'value'));
    }
    if (users.length > 0) {
      setUsersOptions(_.uniqBy(users, 'value'));
      setInitialUsersOptions(_.uniqBy(users, 'value'));
    }
    if (groupId.length > 0) {
      setGroupOptions(_.uniqBy(groupId, 'value'));
      setInitialGroupIdOptions(_.uniqBy(groupId, 'value'));
    }
  };

  useEffect(() => {
    getAllUnitTypes('DASHBOARD_MODULE', 0, -1, {isManaged: true}).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setUnitsData(
            flattenDeep(
              response && response?.unitList?.length > 0
                ? response?.unitList?.map((unitType) =>
                    unitType.units
                      ? unitType.units.map((unit) => ({
                          id: unit.unitId,
                          unitTypeId: unitType.id,
                          name: unit.shortName,
                          color: colors[Math.floor(Math.random() * 50)],
                          description:
                            unitType.name +
                            ' ' +
                            (unit.roomNumber && unit.roomNumber !== 'undefined'
                              ? ' #' + unit.roomNumber
                              : ''),
                          coverImage: unitType.coverImage
                        }))
                      : []
                  )
                : []
            )
          );
          // setUnitTypesList(response);
          setInitialUnitList(response.unitList);

          const tempUnitTypeList = response?.unitList?.filter(
            (unit) => unit.propertyId === '617554d1-be8d-4aeb-acb4-972bb5b258ff'
          );
          setUnitsData(
            flattenDeep(
              tempUnitTypeList
                ? tempUnitTypeList.map((unitType) =>
                    unitType.units
                      ? unitType.units.map((unit) => ({
                          id: unit.unitId,
                          unitTypeId: unitType.id,
                          name: unit.shortName,
                          color: colors[Math.floor(Math.random() * 50)],
                          description:
                            unitType.name +
                            ' ' +
                            (unit.roomNumber && unit.roomNumber !== 'undefined'
                              ? ' #' + unit.roomNumber
                              : ''),
                          coverImage: unitType.coverImage
                        }))
                      : []
                  )
                : []
            )
          );
          setUnitTypesList(tempUnitTypeList);
          setUnitTypeOptions(
            response?.unitList?.map((unitType) => ({
              value: unitType.id,
              label: unitType.name || ''
            }))
          );
        }
      }
    );

    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response);
      }
    });
    getReservations(new Date());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (unitTypeGroupsList.length > 0 && initialUnitList.length > 0) {
      filterDataWithoutDuplicate(initialUnitList, unitTypeGroupsList);
    }
  }, [unitTypeGroupsList, initialUnitList]);

  const getUnitTypeName = (value: ResourceDetails | TreeViewArgs): string => {
    return (value as ResourceDetails).resourceData[(value as any).resource.textField] as string;
  };
  const getUnitTypeDescription = (value: ResourceDetails | TreeViewArgs): string => {
    return (value as ResourceDetails).resourceData.description as string;
  };
  const getUnitTypeImage = (value: ResourceDetails): string => {
    return (value as ResourceDetails).resourceData.coverImage as string;
  };

  const getAreaName = (value: ResourceDetails): string => {
    return (value as ResourceDetails).resourceData.area as string;
  };

  function calculateMonthlyDuration(startTime: string, endTime: string, monthStartDate: Date, monthEndDate: Date): number {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const effectiveStart = startDate < monthStartDate ? monthStartDate : startDate;
    const effectiveEnd = endDate > monthEndDate ? monthEndDate : endDate;

    const duration = Math.ceil((effectiveEnd.getTime() - effectiveStart.getTime()) / (1000 * 3600 * 24)) + 1;
    return duration > 0 ? duration : 0;
}

useEffect(() => {
    if (unitsData && events && currentDataSelected) {
        const selectedYear = currentDataSelected.getFullYear();
        const selectedMonth = currentDataSelected.getMonth();

        const monthStartDate = new Date(selectedYear, selectedMonth, 1);
        const monthEndDate = new Date(selectedYear, selectedMonth + 1, 0);

        const roomDurationMap: { [key: string]: number } = {};
        events.forEach(event => {
            const duration = calculateMonthlyDuration(event.StartTime, event.EndTime, monthStartDate, monthEndDate);
            if (duration > 0) { // Only count if there is an overlap
                if (roomDurationMap[event.id]) {
                    roomDurationMap[event.id] += duration;
                } else {
                    roomDurationMap[event.id] = duration;
                }
            }
        });

        const sortedUnits = [...unitsData].sort((a, b) => {
            const durationA = roomDurationMap[a.id] || 0;
            const durationB = roomDurationMap[b.id] || 0;
            return durationA - durationB;
        });

        setSortedUnitsData(sortedUnits);
        setUniqKey(Date.now());
    }
}, [unitsData, events, currentDataSelected]);


  return (
    <div className={styles['global-calendar']}>
      <div className={styles['global-calendar__reservation-item']}>
        <div className={styles['global-calendar__reservation-item__form-field']}>
          <div className={styles['global-calendar__reservation-item__form-field__label']}>
            Search By
          </div>
          <div className={styles['global-calendar__reservation-item__search-input']}>
            <InputText
              data={{
                defaultValue: unitTypeSearch.value,
                value: unitTypeSearch.value,
                placeholder: 'search by unit or project or unit type groups or area or user'
              }}
              config={{
                maxRows: 1,
                autoFocus: false
              }}
              eventHandlers={{
                onChangeHandler: (value: string, isValid: boolean) => {
                  setUnitTypeSearch({ value, isValid });
                }
              }}
            />
          </div>
        </div>
        <div className={styles['global-calendar__reservation-item__form-field']}>
          <div className={styles['global-calendar__reservation-item__form-field__label']}>Area</div>
          <Select
            value={{ value: area.value, label: area.label }}
            options={areaOptions}
            className={styles['global-calendar__reservation-item__form-field__select']}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setArea(value ? value : { value: '', label: '' });
              setProjectsList(
                initialProjectsList?.filter((project) => project?.areaId?.includes(value?.value))
              );
              setUsersOptions(
                initialUsersOptions?.filter((unit) => unit?.areaId?.includes(value?.value))
              );
              setUnitTypeOptions(
                initialUnitTypes?.filter((unit) => unit?.areaId?.includes(value?.value))
              );
              setProject({ value: '', label: '' });
              setUnitType({ value: '', label: '' });
              setUser({ value: '', label: '' });
              setUnitTypeGroup({ value: '', label: '' });
            }}
          />
        </div>

        <div className={styles['global-calendar__reservation-item__form-field']}>
          <div className={styles['global-calendar__reservation-item__form-field__label']}>
            Project
          </div>
          <Select
            value={{ value: project.value, label: project.label }}
            options={projectsList.map((project) => ({
              value: project.value,
              label: project.label
            }))}
            className={styles['global-calendar__reservation-item__form-field__select']}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setProject(
                value ? { value: value.value, label: value.label } : { value: '', label: '' }
              );
              setUnitType({ value: '', label: '' });
              setUsersOptions(
                initialUsersOptions?.filter((unit) => unit?.propertyId?.includes(value?.value))
              );
              setUnitTypeOptions(
                initialUnitTypes?.filter((unit) => unit?.propertyId?.includes(value?.value))
              );
              setUser({ value: '', label: '' });
            }}
          />
        </div>

        <div className={styles['global-calendar__reservation-item__form-field']}>
          <div className={styles['global-calendar__reservation-item__form-field__label']}>
            Homeowner
          </div>
          <Select
            value={{ value: user.value, label: user.label }}
            options={usersOptions.map((user) => ({
              value: user.value,
              label: user.label
            }))}
            className={styles['global-calendar__reservation-item__form-field__select']}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setUser(
                value ? { value: value.value, label: value.label } : { value: '', label: '' }
              );
              setUnitTypeOptions(
                initialUnitTypes?.filter((unit) => unit?.userId?.includes(value?.value))
              );
              setUnitTypeGroup({ value: '', label: '' });
              setUnitType({ value: '', label: '' });
            }}
          />
        </div>
        <div className={styles['global-calendar__reservation-item__form-field']}>
          <div className={styles['global-calendar__reservation-item__form-field__label']}>
            Rooms
          </div>
          <Select
            value={{ value: unitType.value, label: unitType.label }}
            options={unitTypeOptions.map((room) => ({
              value: room.value,
              label: room.label
            }))}
            className={styles['global-calendar__reservation-item__form-field__select']}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setUnitType(
                value ? { value: value.value || '', label: value.label } : { value: '', label: '' }
              );
              setGroupOptions(
                initialGroupIdOptions?.filter((unit) =>
                  unit?.roomId?.reduce((acc, room) => {
                    if (room?.unitId?.includes(value?.value)) {
                      return true;
                    } else {
                      return acc || false;
                    }
                  }, false)
                )
              );
              setUnitTypeGroup({ value: '', label: '' });
            }}
          />
        </div>
        <div className={styles['global-calendar__reservation-item__form-field']}>
          <div className={styles['global-calendar__reservation-item__form-field__label']}>
            Unit type
          </div>
          <Select
            value={{ value: unitTypeGroup.value, label: unitTypeGroup.label }}
            className={styles['global-calendar__reservation-item__form-field__select']}
            options={groupOptions.map((group) => ({
              value: group.value,
              label: group.label
            }))}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setUnitTypeGroup(
                value ? { value: value.value || '', label: value.label } : { value: '', label: '' }
              );
            }}
          />
        </div>
        <div className={styles['global-calendar__reservation-item__actions']}>
          <Button
            className={styles['global-calendar__reservation-item__search-input__btn']}
            onClick={() => {
              const tempUnitTypeList = initialUnitList?.filter(
                (unit) =>
                  unit.areaId?.includes(area.value !== '' ? area.value : unit.areaId) &&
                  unit.unitTypeGroupId?.includes(
                    unitTypeGroup.value !== '' ? unitTypeGroup.value : unit.unitTypeGroupId
                  ) &&
                  unit.propertyId?.includes(
                    project.value !== '' ? project.value : unit.propertyId
                  ) &&
                  (unitType.value === '' ||
                    unit?.units?.reduce((acc, room) => {
                      if (
                        room?.unitId?.includes(unitType.value !== '' ? unitType.value : unit?.id)
                      ) {
                        return true;
                      } else {
                        return acc || false;
                      }
                    }, false)) &&
                  unit.landlordId?.includes(user.value !== '' ? user.value : unit.landlordId) &&
                  (unitTypeSearch.value === '' ||
                    unit.name?.toLowerCase().includes(unitTypeSearch.value.toLowerCase()) ||
                    unit.area?.toLowerCase().includes(unitTypeSearch.value.toLowerCase()) ||
                    unit.landlordUsername
                      ?.toLowerCase()
                      .includes(unitTypeSearch.value.toLowerCase()) ||
                    unit.propertyName?.toLowerCase().includes(unitTypeSearch.value.toLowerCase()) ||
                    unit?.units.reduce((acc, room) => {
                      if (
                        (room.shortName &&
                          room.shortName.toLowerCase().includes(unitTypeSearch.value)) ||
                        (room.roomNumber &&
                          room.roomNumber.toLowerCase().includes(unitTypeSearch.value)) ||
                        (room.unitId && room.unitId.toLowerCase().includes(unitTypeSearch.value))
                      ) {
                        return true;
                      } else {
                        return acc;
                      }
                    }, false))
              );
              setUnitsData(flattenDeep(
                  tempUnitTypeList
                    ? tempUnitTypeList.map((unitType) =>
                        unitType.units
                          ? unitType.units.map((unit) => ({
                              id: unit.unitId,
                              unitTypeId: unitType.id,
                              name: unit.shortName,
                              color: colors[Math.floor(Math.random() * 50)],
                              description:
                                unitType.name +
                                ' ' +
                                (unit.roomNumber && unit.roomNumber !== 'undefined'
                                  ? ' #' + unit.roomNumber
                                  : ''),
                              coverImage: unitType.coverImage
                            }))
                          : []
                      )
                    : []
                ));

              setUnitTypesList(tempUnitTypeList);
              setTimeout(() => {
                if (scheduleRef && scheduleRef.current)
                  // scheduleRef.current.refreshProperties();
                  scheduleRef.current.refreshTemplates();
              });
            }}
            tracking={{
              action: 'search',
              category: 'unittypes',
              label: 'search-unitypes-create-reservation'
            }}
            size={ButtonSize.medium}
          >
            <SearchIcon></SearchIcon>
          </Button>
          <Button
            className={styles['global-calendar__reservation-item__search-input__btn']}
            onClick={() => {
              filterDataWithoutDuplicate(initialUnitList, unitTypeGroupsList);
              setProject({ value: '', label: '' });
              setUnitType({ value: '', label: '' });
              setUser({ value: '', label: '' });
              setArea({ value: '', label: '' });
              setUnitTypeSearch({ value: '', isValid: true });
              setUnitTypeGroup({ value: '', label: '' });
              setUnitTypesList(initialUnitList);
            }}
            tracking={{
              action: 'clearSearch',
              category: 'unittypes',
              label: 'search-unitypes-create-reservation'
            }}
            size={ButtonSize.medium}
            type={ButtonType.gray}
          >
            clear
          </Button>
        </div>
      </div>
      {events && unitsData ? (
        <div className="schedule-control-section">
          <div className="col-lg-12 control-section">
            <div className="control-wrapper drag-sample-wrapper">
              <div className="schedule-container">
                {lazyLoading && (
                  <div className={styles['global-calendar__lazy-loader']}>
                    <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
                  </div>
                )}
                <ScheduleComponent
                  key={uniqKey}  // Changing key forces re-render
                  ref={scheduleRef}
                  navigating={(event) => {
                    setLazyLoading(true);
                    if (
                      event &&
                      event.currentDate &&
                      event.previousDate &&
                      event.previousDate !== event.currentDate
                    ) {
                      setCurrentDataSelected(event.currentDate);
                      getReservations(event.currentDate);
                    }
                  }}
                  // ref={(schedule) => (scheduleObj = schedule)}
                  cssClass="block-events"
                  width="100%"
                  height="1440px"
                  selectedDate={currentDataSelected}
                  currentView="TimelineMonth"
                  resourceHeaderTemplate={(props: any) => (
                    <div
                      className="template-wrap"
                      onClick={() => {
                        const navigateTo: string =
                          PUBLIC_PATH +
                          (
                            APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig
                          ).EDIT.FULL_PATH.replace(':id', props.resourceData.unitTypeId);
                        window.open(navigateTo, '_blank')?.focus();
                      }}
                    >
                      <div className={styles['global-calendar__unit-type']}>
                        <img
                          className={styles['global-calendar__unit-type-image']}
                          src={
                            getUnitTypeImage(props) ||
                            `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`
                          }
                          alt="unit-type"
                        />
                        <div className={styles['global-calendar__unit-type-description']}>
                          <Tooltip
                            title={
                              <span
                                className={
                                  styles['global-calendar__unit-type-description__tooltip']
                                }
                              >
                                {getUnitTypeName(props)}
                              </span>
                            }
                          >
                            <div className="employee-name">{getUnitTypeName(props)}</div>
                          </Tooltip>
                          <Tooltip
                            title={
                              <span
                                className={
                                  styles['global-calendar__unit-type-description__tooltip']
                                }
                              >
                                {getUnitTypeDescription(props)}
                              </span>
                            }
                          >
                            <div className="employee-designation">
                              {getUnitTypeDescription(props)}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )}
                  eventSettings={{
                    dataSource: events,
                    template: (props) => {
                      return (
                        <Tooltip title={<Typography style={{ fontSize: '1.5em' }}>{props.reservationId || props.blockedDateId}</Typography>}>
                          <div
                            style={{
                              backgroundColor: props.blockedDate
                                ? 'black'
                                : colors[Math.floor(Math.random() * 50)]
                            }}
                            className={styles['global-calendar__reservation']}
                          >
                            <div
                              onClick={() => {
                                const navigateTo = props.blockedDateId
                                  ? PUBLIC_PATH +
                                    (APP_ROUTES.BLOCK_DATES_MODULE.CHILDREN as RouteConfig).LIST
                                      .FULL_PATH
                                  : PUBLIC_PATH +
                                    (
                                      APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig
                                    ).EDIT.FULL_PATH.replace(':id', props.reservationId);
                                window.open(navigateTo, '_blank')?.focus();
                              }}
                            >
                              {props.Subject}
                              {'  '}
                            </div>
                            <button
                              className={styles['global-calendar__reservation__link']}
                              onClick={() => {
                                const navigateTo = props.blockedDateId
                                  ? PUBLIC_PATH +
                                    (APP_ROUTES.BLOCK_DATES_MODULE.CHILDREN as RouteConfig).LIST
                                      .FULL_PATH
                                  : PUBLIC_PATH +
                                    (
                                      APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig
                                    ).EDIT.FULL_PATH.replace(':id', props.reservationId);
                                window.open(navigateTo, '_blank')?.focus();
                              }}
                            >
                              #{props.reservationId || props.blockedDateId}
                            </button>
                          </div>
                        </Tooltip>
                      );
                    }
                  }}
                  group={{ enableCompactView: false, resources: ['Units'] }}
                >
                  <ResourcesDirective>
                    <ResourceDirective
                      field="id"
                      title="Room name"
                      name="Units"
                      allowMultiple={true}
                      dataSource={sortedUnitsData}
                      textField="name"
                      description="description"
                      idField="id"
                      colorField="color"
                    ></ResourceDirective>
                  </ResourcesDirective>
                  <ViewsDirective>
                    {/* <ViewDirective option="Day" /> */}
                    {/* <ViewDirective option="TimelineDay" /> */}
                    <ViewDirective option="TimelineMonth" />
                  </ViewsDirective>
                  <Inject services={[TimelineViews, TimelineMonth, Resize]} />
                </ScheduleComponent>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles['global-calendar__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      )}
    </div>
  );
};
