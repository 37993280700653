
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import KpisContainer  from './containers/Kpis.container';
import { BusinessOnTheBook } from './containers/business-on-the-book.container';
/**
 * function KpisModule components
 */
export const KpisModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.KPIS_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.KPIS_MODULE.CHILDREN as RouteConfig).HOME.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.KPIS_MODULE.CHILDREN as RouteConfig).HOME.FULL_PATH}
        component={KpisContainer}
      />
      {/* <Route
        path={(APP_ROUTES.KPIS_MODULE.CHILDREN as RouteConfig).BUSINESS_ON_THE_BOOK.FULL_PATH}
        component={BusinessOnTheBook}
      /> */}
    </>
  );
};
