import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { UserAuthModel } from '../../models/user-auth.model';
import { UserAuthUIModel } from '../../ui-models/user-auth.ui-model';
import {
  cleanupUserData,
  refreshUserToken,
  setUserNotification
} from '../helpers/auth-helper/auth-helper.service';
import api from '../utils/api/api.service';
import UserAuthMapper from '../../mappers/user-auth.mapper';
import errorHelper from '../utils/api/error-helper.service';
import { setUserProperties } from '../utils/tracking/tracking.service';
import { AuthStatus } from '../../enums/auth.status.enum';
import { UserNotificationsModel } from '../../models/user-notifications.model';
import { ResponseModel } from '../../interfaces/response-model.interface';

/**
 * function to check use token validity
 */
export function checkTokenValidity(): Promise<boolean> {
  return api
    .validateToken()
    .then(
      (response) => {
        if (response.status === 202) {
          return true;
        } else {
          cleanupUserData();
          return false;
        }
      },
      (rejected) => {
        console.error(rejected);
        cleanupUserData();
        return false;
      }
    )
    .catch((error) => {
      console.error(error);
      cleanupUserData();
      return false;
    });
}

/**
 * function to check use token validity
 */
export function refreshUserSession(): Promise<AuthStatus> {
  const userData = refreshUserToken();
  if (userData === null) {
    cleanupUserData();
    return new Promise((resolve) => resolve(AuthStatus.UNAUTHENTICATED));
  } else {
    return api
      .validateToken()
      .then(
        (response) => {
          if (response.status === 202) {
            setUserProperties(userData.userId, userData.email, userData.role || 'guest', userData.roles || ['guest']);
            return AuthStatus.AUTHENTICATED;
          } else if (response.status === 403) {
            return AuthStatus.UNAUTHORIZED;
          } else {
            cleanupUserData();
            return AuthStatus.UNAUTHENTICATED;
          }
        },
        (rejected) => {
          console.error('rejected>>>>', rejected);

          cleanupUserData();
          return AuthStatus.UNAUTHENTICATED;
        }
      )
      .catch((error) => {
        console.error(error);
        cleanupUserData();
        return AuthStatus.UNAUTHENTICATED;
      });
  }
}

/**
 * function to sign up user
 * @param email {string} user email
 * @param password {string} user password
 * @param containerName {string} UI container
 */
export function registerUser(
  email: string,
  password: string,
  containerName: string
): Promise<UserAuthUIModel | ErrorInfo> {
  return api
    .registerUser(email, password)
    .then((response: ApiResponse<UserAuthModel, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = UserAuthMapper.mapToUI(data);
          setUserProperties(mappedData.userId, mappedData.email, mappedData.role || 'visitor', mappedData.roles || ['visitor']);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (api.registerUser as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.registerUser as any).displayName
        );

        return error;
      }
    });
}

/**
 * function to login user
 * @param email {string} user email
 * @param password {string} user password
 * @param containerName {string} UI container
 */
export function loginUser(
  email: string,
  password: string,
  containerName: string,
  token: string | null | undefined
): Promise<UserAuthUIModel | ErrorInfo> {
  return api
    .loginUser(email, password, token)
    .then((response: ApiResponse<UserAuthModel, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = UserAuthMapper.mapToUI(data);
          setUserProperties(mappedData.userId, mappedData.email, mappedData.role || 'visitor', mappedData.roles || ['visitor']);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (api.loginUser as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.loginUser as any).displayName
        );

        return error;
      }
    });
}

/**
 * function to change user password
 * @param token {string} server side generated token
 * @param password {string} user new password
 * @param containerName {string} UI container
 */
export function changeUserPassword(
  token: string,
  password: string,
  containerName: string
): Promise<UserAuthUIModel | ErrorInfo> {
  return api
    .changePassword(token, password)
    .then((response: ApiResponse<UserAuthModel, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = UserAuthMapper.mapToUI(data);
          setUserProperties(mappedData.userId, mappedData.email, mappedData.role || 'visitor', mappedData.roles || ['visitor']);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (api.loginUser as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.loginUser as any).displayName,
          response.data?.message
        );

        return error;
      }
    });
}

/**
 * function to forgot user password
 * @param email {string} user email
 * @param containerName {string} UI container
 */
export function forgotUserPassword(email: string, containerName: string): Promise<{} | ErrorInfo> {
  return api.forgotPassword(email).then((response: ApiResponse<{}, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data) {
        return data;
      } else {
        const error: ErrorInfo = errorHelper(
          0,
          '',
          containerName,
          (api.loginUser as any).displayName
        );
        return error;
      }
    } else {
      const error: ErrorInfo = errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        containerName,
        (api.loginUser as any).displayName
      );

      return error;
    }
  });
}

export function userRefreshToken(
  refreshToken: string,
  containerName?: string
): Promise<UserAuthUIModel | ErrorInfo> {
  return api.refreshToken(refreshToken).then((response: ApiResponse<UserAuthModel, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data) {
        const mappedData = UserAuthMapper.mapToUI(data);
        setUserProperties(mappedData.userId, mappedData.email, mappedData.role || 'guest');
        return mappedData;
      } else {
        const error: ErrorInfo = errorHelper(
          0,
          '',
          containerName || 'DASHBOARD_MODULE',
          (api.refreshToken as any).displayName
        );
        return error;
      }
    } else {
      const error: ErrorInfo = errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        containerName || 'DASHBOARD_MODULE',
        (api.refreshToken as any).displayName
      );

      return error;
    }
  });
}

export function getUserNotification(containerName?: string) {
  return api
    .getUserNotifications()
    .then((response: ApiResponse<ResponseModel<UserNotificationsModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          setUserNotification(data.data);
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName || 'HOME_MODULE',
            (api.getUserNotifications as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName || 'HOME_MODULE',
          (api.getUserNotifications as any).displayName
        );

        return error;
      }
    });
}
