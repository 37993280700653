import Cookies from 'js-cookie';
import {
  userDataCleanup,
  userDataSuccess,
  userNotificationSuccess
} from '../../../redux/actions/auth.actions';
import {
  USER_ACCESS_TOKEN,
  USER_ID_COOKIE_NAME,
  USER_REFRESH_TOKEN
} from '../../../config/cookies.config';
import ReduxStore from '../../../redux/store';
import { UserAuthUIModel } from '../../../ui-models/user-auth.ui-model';
import jwt_decode from 'jwt-decode';
import { UserNotificationsModel } from '../../../models/user-notifications.model';
import { UserAuthModel } from '../../../models/user-auth.model';
import { UserRole } from '../../../enums/user-role.enum';

/**
 * function to get user token from data store
 * @returns {string | undefined}
 */
export function getUserAccessToken(): string | undefined {
  return ReduxStore.getState().auth.accessToken;
}

export function getUserRefreshToken(): string | undefined {
  return ReduxStore.getState().auth.refreshToken || Cookies.get(USER_REFRESH_TOKEN);
}

export function refreshUserToken(): UserAuthUIModel | null {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  const refreshToken = Cookies.get(USER_REFRESH_TOKEN);
  if (accessToken && accessToken !== 'undefined' && refreshToken && refreshToken !== 'undefined') {
    const { email, userId, role, permissions, roles } = jwt_decode<UserAuthUIModel>(accessToken);
    const userData: UserAuthUIModel = {
      accessToken,
      refreshToken,
      email,
      userId,
      tokenExpiresIn: 365,
      role,
      roles,
      permissions
    };
    // ReduxStore.dispatch(registerUserSuccess(userData));
    ReduxStore.dispatch(
      userDataSuccess({
        accessToken: userData.accessToken,
        refreshToken: userData.refreshToken,
        tokenExpiresIn: userData.tokenExpiresIn,
        userId: userData.userId,
        email: userData.email,
        role: userData.role,
        roles: userData.roles,
        permissions: userData.permissions
      })
    );
    return userData;
  } else {
    return null;
  }
}

/**
 * function to set user token to cookies and data store
 * @param userData
 */
export function setUserToken(userData: UserAuthUIModel): void {
  Cookies.set(USER_ACCESS_TOKEN, userData.accessToken, { expires: userData.tokenExpiresIn });
  Cookies.set(USER_ID_COOKIE_NAME, userData.userId, { expires: userData.tokenExpiresIn });
  if (userData.refreshToken) {
    Cookies.set(USER_REFRESH_TOKEN, userData.refreshToken, {
      expires: 90
    });
  }
  // ReduxStore.dispatch(registerUserSuccess(userData));
  ReduxStore.dispatch(
    userDataSuccess({
      accessToken: userData.accessToken,
      refreshToken: userData.refreshToken,
      tokenExpiresIn: userData.tokenExpiresIn,
      userId: userData.userId,
      email: userData.email,
      role: userData.role,
      roles: userData.roles
    })
  );
}

export function hasAccess(allowedPermissions: string[]): boolean {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { permissions } = jwt_decode<UserAuthUIModel>(accessToken);
    if (permissions) {
      return allowedPermissions.some((item) => permissions.includes(item));
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function hasRole(allowedRoles: UserRole[]): boolean {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { role, roles } = jwt_decode<UserAuthUIModel>(accessToken);
    if (role && roles) {
      return allowedRoles.indexOf(role) > -1 || allowedRoles.some(r => roles?.includes(r));
    } else if (role) {
      return allowedRoles.indexOf(role) > -1;
    } else if (roles) {
      return allowedRoles.some(r => roles?.includes(r));
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * function to clean up user data from cookies and data store
 */
export function cleanupUserData() {
  ReduxStore.dispatch(userDataCleanup());
  Cookies.remove(USER_ACCESS_TOKEN);
  Cookies.remove(USER_REFRESH_TOKEN);
  Cookies.remove(USER_ID_COOKIE_NAME);
}

export function setUserNotification(userNotifications: UserNotificationsModel): void {
  // ReduxStore.dispatch(registerUserSuccess(userData));
  ReduxStore.dispatch(userNotificationSuccess(userNotifications));
}
