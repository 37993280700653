import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Redirect } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { PUBLIC_PATH, APP_ROUTES, SETTINGS_ROUTES } from './shared/config/routes-config';
import ReduxStore from './shared/redux/store';
import ReactNotification from 'react-notifications-component';

// import global styles
import './index.scss';
import 'react-notifications-component/dist/theme.css';
// import tracking section
import { initializeTrackingTools } from './shared/services/utils/tracking/tracking.service';
// import i18n config
import { initializeI18n } from './shared/config/i18n.config';
import { AuthModule } from './AuthModule/auth.module';
import { refreshUserSession, getUserNotification } from './shared/services/data/auth.data';
import DefaultLayout from './shared/layouts/default/default.layout';
import BlankLayout from './shared/layouts/blank/blank.layout';
import { AreaModule } from './AreaModule/area.module';
import { PropertyModule } from './PropertyModule/property.module';
import { AmenityModule } from './AmenityModule/amenity.module';
import { UnitTypeModule } from './UnitTypeModule/unit-type.module';
import { BookingModule } from './BookingModule/booking.module';
import { BlockDatesModule } from './BlockDatesModule/block-dates.module';
import { DashboardModule } from './DashboardModule/dashboard.module';
import { ReservationModule } from './ReservationModule/reservation.module';
import { UnitTypeGroupModule } from './unitTypeGroupModule/unit-type-group.module';
import { AuthStatus } from './shared/enums/auth.status.enum';
import { RouteConfig } from './shared/interfaces/routes-config.interface';
import { UserRole } from './shared/enums/user-role.enum';
import { PaymentsModule } from './paymentsModule/payments.module';
import { UserModule } from './UserModule/user.module';
import { ConfigurationModule } from './ConfigurationModule/configuration.module';
import { ReportsModule } from './ReportsModule/reports.module';
import { ExpensesModule } from './ExpensesModule/expenses.module';
import { PayoutModule } from './PayoutModule/payout.module';
import { KpisModule } from './KpisModule/KpisModule.module';

// import app modules

const store = ReduxStore;
initializeTrackingTools();
initializeI18n();
const history = createBrowserHistory({ basename: PUBLIC_PATH });
// refresh user token and check validity
refreshUserSession().then((response) => {
  if (
    response === AuthStatus.UNAUTHORIZED &&
    window.location.href.indexOf(
      (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH
    ) < 0
  ) {
    console.log('User not premitted to access admin page');
    window.location.href =
      PUBLIC_PATH + (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH;
    // setTimeout(() => {
    //   history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH);
    // }, 500);
  } else {
    getUserNotification().then();
  }
});
ReactDOM.render(
  <>
    <ReactNotification />
    <Provider store={store}>
      <Router history={history}>
        <BrowserRouter basename={PUBLIC_PATH}>
          <Switch>
            <Route exact={true} path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact={true} path="/home">
              <Redirect to={APP_ROUTES.DASHBOARD_MODULE.PATH} />
            </Route>
            <BlankLayout
              authenticationRequired={false}
              component={AuthModule}
              path={APP_ROUTES.AUTH_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ADMIN]}
              component={AreaModule}
              path={APP_ROUTES.AREA_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.ADMIN
              ]}
              component={UnitTypeGroupModule}
              path={APP_ROUTES.UNIT_TYPE_GROUP_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.ADMIN
              ]}
              component={AmenityModule}
              path={APP_ROUTES.AMENITY_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.ADMIN
              ]}
              component={PropertyModule}
              path={APP_ROUTES.PROPERTY_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.RESERVATION_AGENT,
                UserRole.RESERVATION_MANAGER,
                UserRole.ADMIN,
                UserRole.COMMERCIAL_MANAGER,
                UserRole.RESERVATIONS,
                UserRole.HOSPITALITY_AGENT,
                UserRole.HOSPITALITY_MANAGER,
                UserRole.FINANCE_MANAGER,
                UserRole.FINANCE_SPECIALIST,
                UserRole.OPERATION_MANAGER,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST
              ]}
              component={ReservationModule}
              path={APP_ROUTES.RESERVATION_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.RESERVATION_MANAGER,
                UserRole.ADMIN
              ]}
              component={UnitTypeModule}
              path={APP_ROUTES.UNIT_TYPE_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.FINANCE_MANAGER,
                UserRole.FINANCE_SPECIALIST,
                UserRole.ADMIN
              ]}
              component={ExpensesModule}
              path={APP_ROUTES.EXPENSES_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.FINANCE_MANAGER,
                UserRole.ADMIN,
                UserRole.FINANCE_SPECIALIST
              ]}
              component={PayoutModule}
              path={APP_ROUTES.PAYOUT_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.RESERVATION_AGENT,
                UserRole.RESERVATION_MANAGER,
                UserRole.ADMIN,
                UserRole.COMMERCIAL_MANAGER,
                UserRole.RESERVATIONS

              ]}
              component={BookingModule}
              path={APP_ROUTES.BOOKING_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.RESERVATION_AGENT,
                UserRole.RESERVATION_MANAGER,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.RESERVATIONS,
                UserRole.ADMIN
              ]}
              component={BlockDatesModule}
              path={APP_ROUTES.BLOCK_DATES_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.SALES_SPECIALIST,
                UserRole.ADMIN,
                UserRole.FINANCE_MANAGER,
                UserRole.FINANCE_SPECIALIST,
                UserRole.RESERVATION_MANAGER,
                UserRole.RESERVATION_AGENT,
                UserRole.RESERVATIONS,
                UserRole.HOSPITALITY_AGENT,
                UserRole.HOSPITALITY_MANAGER,
                UserRole.HOUSE_KEEPING_MANAGER,
                UserRole.OPERATION_MANAGER,
                UserRole.COMMERCIAL_MANAGER
              ]}
              component={DashboardModule}
              path={APP_ROUTES.DASHBOARD_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.ADMIN,
                UserRole.OPERATION_MANAGER,
                UserRole.RESERVATION_MANAGER,
                UserRole.HOSPITALITY_MANAGER,
                UserRole.FINANCE_MANAGER,
                UserRole.COMMERCIAL_MANAGER,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST
              ]}
              component={KpisModule}
              path={APP_ROUTES.KPIS_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.FINANCE_MANAGER,
                UserRole.ADMIN,
                UserRole.FINANCE_SPECIALIST,
                UserRole.RESERVATION_MANAGER
              ]}
              component={PaymentsModule}
              path={APP_ROUTES.PAYMENTS_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.FINANCE_MANAGER,
                UserRole.ADMIN,
                UserRole.FINANCE_SPECIALIST
              ]}
              component={ReportsModule}
              path={APP_ROUTES.REPORTS_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[
                UserRole.E_COMMERCE,
                UserRole.ECOMMERCE_SPECIALIST,
                UserRole.ECOMMERCE_SENIOR_SPECIALIST,
                UserRole.SALES_SPECIALIST,
                UserRole.ADMIN,
                UserRole.RESERVATIONS,
                UserRole.RESERVATION_MANAGER,
                UserRole.RESERVATION_AGENT,
                UserRole.HOSPITALITY_MANAGER,
                UserRole.HOSPITALITY_AGENT,
                UserRole.OPERATION_MANAGER,
                UserRole.COMMERCIAL_MANAGER
              ]}
              component={UserModule}
              path={APP_ROUTES.USER_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[UserRole.ADMIN]}
              component={ConfigurationModule}
              path={SETTINGS_ROUTES.ACCOUNT_CONFIGURATION_MODULE.PATH}
            />
          </Switch>
        </BrowserRouter>
      </Router>
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
